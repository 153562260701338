import { FieldBlock } from "../../types";
import { isValid } from "date-fns";
import { isNumeric } from "utils/isNumeric";
import {
  FieldBlockConditionalLogicItem,
  FieldBlockConditionalLogicItemSchema,
} from "../../schema";
import { getAllowedOperatorsForField } from "./field";
import {
  isValidEmptyValueForCondition,
  isValidValueForCondition,
} from "./condition";

function validateObjectValueForDropdown(value: unknown) {
  return (
    typeof value === "object" &&
    value !== null &&
    "value" in value &&
    typeof value.value === "string"
  );
}

export type DropdownValue =
  | null
  | undefined
  | string
  | string[]
  | { value: string; label: string }
  | { value: string; label: string }[];
type NumericValue = null | undefined | number | string;
type StringValue = string | null | undefined;

type FieldTypeToValueMap = {
  number: NumericValue;
  currency: NumericValue;
  dropdown: DropdownValue;
  checkbox: boolean;
  "yes-no": boolean;
  "date-picker": string | Date;
  "file-upload": Record<string, unknown>;
  text: StringValue;
  email: StringValue;
  "phone-number": StringValue;
  link: StringValue;
};

type ValidateFieldTypeMap<T> = T extends FieldBlock["config"]["inputType"]
  ? T extends keyof FieldTypeToValueMap
    ? FieldTypeToValueMap[T]
    : StringValue
  : never;

export function isValidValueForFieldType<
  T extends FieldBlock["config"]["inputType"],
>(value: unknown, type: T): value is ValidateFieldTypeMap<T> {
  switch (type) {
    case "number":
    case "currency":
      return (
        value === "" ||
        value === null ||
        value === undefined ||
        typeof value === "number" ||
        (typeof value === "string" && isNumeric(value))
      );
    case "dropdown":
      return (
        value === null ||
        value === undefined ||
        typeof value === "string" ||
        (Array.isArray(value) &&
          value.every(
            (v) => typeof v === "string" || validateObjectValueForDropdown(v)
          )) ||
        validateObjectValueForDropdown(value)
      );
    case "checkbox":
    case "yes-no":
      return typeof value === "boolean";
    case "date-picker":
      return isValid(new Date(value as Date));
    case "file-upload":
      return typeof value !== "boolean" && typeof value !== "number";
    default:
      return value === null || value === undefined || typeof value === "string";
  }
}
export function getConditionalLogicValidationError(
  condition: FieldBlockConditionalLogicItem,
  supportedFields: FieldBlock[]
) {
  const fieldBlock = supportedFields.find(
    (f) => f.config.id === condition.fieldId
  );
  if (!fieldBlock) return "Field not found";

  const parseResult = FieldBlockConditionalLogicItemSchema.safeParse(condition);
  if (parseResult.success === false) {
    return parseResult.error.issues[0].message;
  }

  const allowedOperators = getAllowedOperatorsForField(fieldBlock);

  if (!allowedOperators.includes(condition.operator)) {
    return "Invalid operator for field";
  }

  if (
    !isValidEmptyValueForCondition(condition, fieldBlock) &&
    !isValidValueForCondition(condition.value, fieldBlock)
  ) {
    return "Invalid value for condition";
  }

  return ""; // no error message
}
