import "zod-openapi/extend";
import {
  BlockBaseSchema,
  BlockIdSchema,
  ComponentBlockStyleSchema,
} from "block-system/types/base";
import { z } from "zod";
import { blockType } from "./constants";
import { LinkCardSchema } from "../LinksCard/schema";

export const LinksBlockSchema = BlockBaseSchema.extend({
  type: z.literal(blockType),
  config: z.object({
    id: BlockIdSchema.optional(),
    headerTitle: z.string(),
    style: ComponentBlockStyleSchema.optional(),
  }),
  children: z.array(LinkCardSchema),
}).openapi({ ref: "LinksBlock" });
