import { cn } from "@/block-system/brickz/lib/utils";
import { ReactNode } from "react";

export function StandardBlockZeroState({
  icon,
  text,
  className,
}: {
  icon: ReactNode;
  text?: string;
  className?: string;
}) {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <div
        className={cn(
          "bg-muted",
          "h-[200px] w-[520px] min-w-[300px]",
          "flex items-center justify-center",
          "mx-auto",
          "rounded",
          className
        )}
      >
        <div className="flex flex-col items-center gap-4">
          <div
            className={cn(
              "flex items-center justify-center",
              "h-10 w-10",
              "rounded",
              "bg-card text-muted-foreground"
            )}
          >
            {icon}
          </div>
          {text ? (
            <span className="text-sm text-muted-foreground">{text}</span>
          ) : null}
        </div>
      </div>
    </div>
  );
}
