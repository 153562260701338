import { upperFirst } from "lodash";
import { PreviewMode, PreviewModeSchema } from "lib/theme/schema";

/**
 * A convenience function for the Appearance component that modifies a theme
 * color name to its dark mode variant while maintaining type safety.
 */
export function darkifyName<T extends string>(
  mode: PreviewMode,
  name: T
): T | `dark${Capitalize<T>}` {
  if (mode === PreviewModeSchema.enum.Dark) {
    return `dark${upperFirst(name)}`;
  }

  return name;
}
