import { cn } from "@/utils/cn";
import ReactMarkdown from "react-markdown";
import { ReactMarkdownOptions } from "react-markdown/lib/react-markdown";
import rehypeHighlight from "rehype-highlight";
import remarkGfm from "remark-gfm";

export function Markdown(props: {
  openInSameTab?: boolean;
  children: string;
  className?: string;
  components?: ReactMarkdownOptions["components"];
}) {
  return (
    <ReactMarkdown
      className={cn("prose w-full max-w-full", props.className)}
      linkTarget={props.openInSameTab ? "_self" : "_blank"}
      /**
       * `rehypeHighlight` to support code highlighting.
       * `remarkGfm` to support tables and such.
       */
      rehypePlugins={[[rehypeHighlight, { ignoreMissing: true }], remarkGfm]}
      components={{
        ...(props.components ?? {}),
      }}
    >
      {props.children}
    </ReactMarkdown>
  );
}
