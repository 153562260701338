import { FileInfo } from "@uploadcare/react-widget";
import { useInterfacesTheme } from "@/lib/theme/ThemeProvider";
import { cn } from "@/block-system/brickz/lib/utils";
import { Loader2 } from "lucide-react";
import {
  MdOutlineTextSnippet,
  MdCheckCircle,
  MdCancel,
  MdClose,
} from "react-icons/md";
import { UploadStatusOutput_New } from "@/utils/trpc";
import styles from "./FileUploadPreview.styles.module.css";

const Placeholder = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      className={cn(
        "flex shrink items-center gap-2",
        "text-sm text-card-foreground/60",
        styles.placeholder
      )}
    >
      {children}
    </div>
  );
};

export function FileUploadPreview({
  file,
  onRemove,
  uploadStatus,
  statusQueryError,
}: {
  file: FileInfo;
  onRemove: (file: FileInfo) => void;
  uploadStatus: UploadStatusOutput_New[number] | undefined;
  statusQueryError: boolean;
}) {
  const interfacesTheme = useInterfacesTheme();

  const isSuccess = file.isStored && uploadStatus?.status === "success";
  const isPending = !uploadStatus || uploadStatus?.status === "pending";
  const isError =
    !file.isStored || uploadStatus?.status === "failure" || statusQueryError;
  const fileSize = Math.round((file?.size ?? 0) / 1024);

  return (
    <div className={cn("container", styles.filePreviewContainer)}>
      <div className="flex items-center justify-between rounded-[6px] border bg-gray-50 p-2">
        <Placeholder>
          {isPending ? (
            <div className="flex items-center gap-2">
              <div className="relative flex h-6 w-6 items-center justify-center">
                <Loader2 className="animate-spin" size={24} />
              </div>
              <span className={cn("text-sm text-zi-black", styles.fileName)}>
                {file?.name}
              </span>
              <span className="text-sm text-muted-foreground">
                {fileSize} Kb
              </span>
            </div>
          ) : null}

          {isSuccess ? (
            <div className="flex items-center gap-2">
              {file.isImage ? (
                <div className="relative h-6 w-6 border border-zi-lightGray">
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <img
                    src={file.cdnUrl ?? undefined}
                    alt=""
                    className="h-full w-full object-cover"
                  />
                </div>
              ) : (
                <MdOutlineTextSnippet size={24} />
              )}
              <span className={cn("text-sm text-zi-black", styles.fileName)}>
                {file?.name}
              </span>
              <span className="text-sm text-muted-foreground">
                {fileSize} Kb
              </span>
              <div className="flex items-center justify-center rounded-full">
                <MdCheckCircle size={20} color="green" />
              </div>
            </div>
          ) : null}

          {isError ? (
            <div className="flex items-start gap-2">
              <MdOutlineTextSnippet size={24} />
              <div className="flex flex-col">
                <div className="flex items-center gap-2">
                  <span
                    className={cn("text-sm text-zi-black", styles.fileName)}
                  >
                    {file?.name || "Document"}
                  </span>
                  <MdCancel size={20} color="red" />
                </div>
                <span className="text-sm">File upload error</span>
              </div>
            </div>
          ) : null}
        </Placeholder>

        <button
          type="button"
          onClick={() => onRemove(file)}
          className={cn("text-muted-foreground hover:text-foreground", {
            "text-zi-darkGray hover:text-zi-black": !interfacesTheme,
            "text-muted-foreground hover:text-foreground": interfacesTheme,
          })}
        >
          <MdClose size={20} />
        </button>
      </div>
    </div>
  );
}
