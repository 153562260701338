import { Icon } from "@zapier/design-system";
import { styled } from "lib/theme";
import { ReactNode } from "react";

import { Modal as ZModal } from "components/Modal";
import { cn } from "utils/cn";
import { useInterfacesTheme } from "lib/theme/ThemeProvider";

export const ModalHeader = styled.div`
  margin-bottom: 30px;
  color: hsl(var(--card-foreground));
`;

export const ModalContent = styled.div`
  position: relative;

  padding: 40px;

  overflow-x: hidden;
  overflow-y: scroll;

  @media screen and (max-width: 599px) {
    width: 100vw;
    height: 100vh;

    padding: 20px;
    box-shadow: 0 0 0 1px #fff;
  }

  @media screen and (min-width: 600px) {
    min-width: 550px;
    max-width: 970px;
    max-height: 650px;

    border-radius: 12px;
    box-shadow: 0px 12px 40px 0 rgba(0, 0, 0, 0.15);
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  color: ${({ theme }) => theme.app.colors.gray};
  cursor: pointer;
`;

export function Modal(props: {
  onClosed: () => void;
  children: ReactNode;
  label?: string;
  className?: string;
}) {
  const interfacesTheme = useInterfacesTheme();

  return (
    <ZModal onClosed={props.onClosed} aria-label={props.label}>
      <ModalContent
        className={cn(props.className, {
          "text bg-card text-base text-card-foreground": interfacesTheme,
        })}
      >
        {props.children}
        <CloseButton onClick={() => props.onClosed()}>
          <Icon name="formX" size={30} aria-hidden={true} />
          <span className="sr-only">Close modal</span>
        </CloseButton>
      </ModalContent>
    </ZModal>
  );
}
