import { Modal } from "block-system/components/Modal";
import { Icon } from "@zapier/design-system";
import { Button } from "block-system/components";
import { Text } from "block-system/components/Text";
import type Stripe from "stripe";
import { animateTransition } from "block-system/blocks/__shared__/transition";

const modalTitles: Record<Stripe.Checkout.Session.Status, string> = {
  complete: "Payment successful",
  expired: "Payment expired",
  open: "Payment failed or was cancelled",
};

export function StripeCheckoutStatusModal({
  checkoutStatus,
  onClosed,
}: {
  checkoutStatus: Stripe.Checkout.Session.Status;
  onClosed: () => void;
}) {
  return (
    <Modal
      label={modalTitles[checkoutStatus]}
      onClosed={() => {
        animateTransition(() => {
          onClosed();
        });
      }}
    >
      {checkoutStatus === "complete" ? (
        <StripeCheckoutSuccess
          onClose={() => {
            animateTransition(() => {
              onClosed();
            });
          }}
        />
      ) : checkoutStatus === "expired" ? (
        <StripeCheckoutExpired
          onClose={() => {
            animateTransition(() => {
              onClosed();
            });
          }}
        />
      ) : (
        <StripeCheckoutFailed
          onClose={() => {
            animateTransition(() => {
              onClosed();
            });
          }}
        />
      )}
    </Modal>
  );
}

export function StripeCheckoutSuccess({ onClose }: { onClose: () => void }) {
  return (
    <div className={"flex flex-col items-center"}>
      <Icon
        name="formCheckCircle"
        size={40}
        color={"StatusSuccessWeaker"}
        canAcceptPointerEvents={false}
      />
      <Text type={"modalTitle"} as="h2" className={"mb-[30px] mt-[13px]"}>
        {modalTitles.complete}
      </Text>
      <Button
        className={"w-[max-content]"}
        type="button"
        onClick={() => {
          onClose();
        }}
      >
        Close
      </Button>
    </div>
  );
}

export function StripeCheckoutFailed({ onClose }: { onClose: () => void }) {
  return (
    <div className={"flex flex-col items-center"}>
      <Icon
        name="formXCircle"
        size={40}
        color={"BrandOrange"}
        canAcceptPointerEvents={false}
      />
      <Text type={"modalTitle"} as="h2" className={"mb-[30px] mt-[13px]"}>
        {modalTitles.open}
      </Text>
      <Button
        className={"w-[max-content]"}
        type="button"
        onClick={() => {
          onClose();
        }}
      >
        Close
      </Button>
    </div>
  );
}

export function StripeCheckoutExpired({ onClose }: { onClose: () => void }) {
  return (
    <div className={"flex flex-col items-center"}>
      <Icon name="alertCircleColor" size={40} canAcceptPointerEvents={false} />
      <Text type={"modalTitle"} as="h2" className={"mb-[30px] mt-[13px]"}>
        {modalTitles.expired}
      </Text>
      <Button
        className={"w-[max-content]"}
        type="button"
        onClick={() => {
          onClose();
        }}
      >
        Close
      </Button>
    </div>
  );
}
