import { EmbedProvider } from "lib/context/embed-context";
import Head from "next/head";
import { PageContextProvider } from "@/lib/context/page-context";
import { CurrentPageProvider } from "@/lib/context/current-page-context";
import { CurrentPagesProvider } from "@/lib/context/current-pages-context";
import { DatasourcesProvider } from "@/lib/context/datasources-provider";
import { Page } from "../Page";
import { ReportInterfaceButton } from "../PublishedPageSSR/PublishedPageRenderer";
import PoweredByZapier from "../PoweredByZapier";
import { useFeatureAccessCheck } from "@/lib/hooks/useFeatureAccessCheck";
import { ConsumerProject } from "@/server/schemas/projects";
import { CurrentConsumerProjectProvider } from "lib/context/current-consumer-project-context";
import { PagePublicSchema } from "@/server/schemas/pages";
import { ServerAuthErrorToast } from "../ProjectAuth/ProjectAuthPage";

export function LoginPage({
  project,
  loginPage,
}: {
  project: ConsumerProject;
  loginPage: PagePublicSchema;
}) {
  const isPaidUser = useFeatureAccessCheck("analytics", {
    ...project.creator,
    paidFeatureAccess: project.paidFeatureAccess,
  });

  return (
    <CurrentConsumerProjectProvider project={project}>
      <EmbedProvider embed={{ isEmbed: false }}>
        <Head>
          <title>Log in</title>
        </Head>
        <PageContextProvider
          isEditing={false}
          isEmbedded={false}
          projectId={project.id}
          pageId={loginPage.id}
        >
          <CurrentPageProvider page={loginPage}>
            <CurrentPagesProvider pages={[]}>
              <DatasourcesProvider>
                <Page blocks={loginPage.content.blocks ?? []} />
              </DatasourcesProvider>
            </CurrentPagesProvider>
          </CurrentPageProvider>
          <PoweredByZapier hasChatbot={false} position="fixed-portal" />
          {!isPaidUser ? <ReportInterfaceButton /> : null}
        </PageContextProvider>
      </EmbedProvider>
      <ServerAuthErrorToast />
    </CurrentConsumerProjectProvider>
  );
}
