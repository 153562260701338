import { ReactNode, useState } from "react";
import { Colors, Icon, Text, Typography } from "@zapier/design-system";
import { css, styled } from "lib/theme";

export const SectionWrapper = styled.div<{
  $noTopPadding: boolean;
  $topBorder: boolean;
}>`
  padding-top: var(
    --zds-space-${({ $noTopPadding }) => ($noTopPadding ? 0 : 20)}
  );

  ${({ $topBorder }) => {
    return $topBorder
      ? css`
          border-top: 1px solid ${Colors.GrayWarm3};
        `
      : css`
          &:not(:first-child) {
            border-top: 1px solid ${Colors.GrayWarm3};
          }
        `;
  }}
`;

const SectionSubtitle = styled.span`
  font-family: ${Typography.base.fontFamily};
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  color: ${Colors.GrayWarm6};
  letter-spacing: 1px;
`;

const SectionContent = styled.div``;

type BaseProps = {
  title?: string;
  subtitle?: string;
  topBorder?: boolean;
  noTopPadding?: boolean;
  children: ReactNode;
};

type CollapsibleSectionProps = BaseProps & {
  collapsible: true;
  startCollapsed?: boolean;
};

type StaticSectionProps = BaseProps & {
  collapsible?: never;
};

export function Section({
  title,
  subtitle,
  topBorder = false,
  noTopPadding = false,
  children,
  ...variantProps
}: CollapsibleSectionProps | StaticSectionProps) {
  if (variantProps.collapsible) {
    return (
      <CollapsibleSection
        title={title}
        subtitle={subtitle}
        startCollapsed={variantProps.startCollapsed}
        noTopPadding={noTopPadding}
        topBorder={topBorder}
      >
        {children}
      </CollapsibleSection>
    );
  }

  return (
    <StaticSection
      title={title}
      subtitle={subtitle}
      noTopPadding={noTopPadding}
      topBorder={topBorder}
    >
      {children}
    </StaticSection>
  );
}

const StaticSectionHeaderContainer = styled.div`
  padding: 15px 20px;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;

function StaticSection({
  title,
  subtitle,
  children,
  noTopPadding: providedNoTopPadding,
  topBorder = false,
}: {
  title?: string;
  subtitle?: string;
  children?: ReactNode;
  noTopPadding?: boolean;
  topBorder?: boolean;
}) {
  const hasHeader: boolean = title != null;
  const noTopPadding: boolean = hasHeader || providedNoTopPadding || false;

  const renderAs = title != null ? "section" : "div";

  return (
    <SectionWrapper
      $noTopPadding={noTopPadding}
      $topBorder={topBorder}
      as={renderAs}
    >
      {hasHeader ? (
        <StaticSectionHeaderContainer>
          <Text type="ParagraphHeader2" tag="h2">
            {title}
          </Text>
          {subtitle ? <SectionSubtitle>{subtitle}</SectionSubtitle> : null}
        </StaticSectionHeaderContainer>
      ) : null}
      <SectionContent>{children}</SectionContent>
    </SectionWrapper>
  );
}

const CollapsibleSectionSummary = styled.summary`
  list-style: none;

  ::-webkit-details-marker {
    display: none;
  }

  padding: 15px 20px;

  &[data-open="false"] {
    background-color: ${Colors.PrimeWhite};
  }

  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;

function CollapsibleSection({
  title,
  subtitle,
  children,
  startCollapsed = true,
  noTopPadding: providedNoTopPadding,
  topBorder,
}: {
  title?: string;
  subtitle?: string;
  children?: ReactNode;
  startCollapsed?: boolean;
  noTopPadding?: boolean;
  topBorder: boolean;
}) {
  const [isOpen, setIsOpen] = useState(!startCollapsed);

  const showSubtitle = subtitle != null && !isOpen;
  const showTitle = title != null;

  const hasHeader: boolean = title != null;
  const noTopPadding: boolean = hasHeader || providedNoTopPadding || false;

  const handleToggle = (event: React.MouseEvent<HTMLDetailsElement>) => {
    setIsOpen(event.currentTarget.open);
  };

  return (
    <SectionWrapper
      $noTopPadding={noTopPadding}
      $topBorder={topBorder}
      as="details"
      onToggle={handleToggle}
      open={isOpen}
    >
      <CollapsibleSectionSummary
        data-open={isOpen}
        data-testid="section-header"
      >
        {showTitle ? (
          <Text type="ParagraphHeader2" tag="span">
            {title}
          </Text>
        ) : null}
        <Icon
          name={isOpen ? "arrowSmallUp" : "arrowSmallDown"}
          color="GrayWarm6"
          size={24}
        />
        {showSubtitle ? <SectionSubtitle>{subtitle}</SectionSubtitle> : null}
      </CollapsibleSectionSummary>
      <SectionContent>{children}</SectionContent>
    </SectionWrapper>
  );
}
