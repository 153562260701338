import { Colors, Icon, IconName, Text } from "@zapier/design-system";
import { styled } from "lib/theme";

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  border-radius: 6px;
  padding: 15px;
  background-color: ${Colors.GrayWarm10};
`;

const IconBeforeWrapper = styled.div`
  margin-right: 15px;
  height: 45px;
  width: 45px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 6px;
  background-color: ${Colors.GrayWarm9};
`;

const IconAfterWrapper = styled.div`
  margin-left: 15px;
  height: 45px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextBlockWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
`;

export type Props = {
  title: string;
  description?: string;
  iconBefore?: IconName;
  iconAfter?: IconName;
};

/**
 * Naming's hard ¯\_(ツ)_/¯
 *
 * This was really just made for the <InformativeBlockErrorState /> component
 */
export function EditorCallout({
  title,
  description,
  iconBefore,
  iconAfter,
}: Props) {
  return (
    <Wrapper>
      {iconBefore ? (
        <IconBeforeWrapper>
          <Icon name={iconBefore} color="GrayWarm5" size={34} isBlock />
        </IconBeforeWrapper>
      ) : null}
      <TextBlockWrapper>
        <Text type="Body4" color="PrimeWhite">
          {title}
        </Text>
        {description ? (
          <Text type="SmallPrint1" color="PrimeWhite">
            {description}
          </Text>
        ) : null}
      </TextBlockWrapper>
      {iconAfter ? (
        <IconAfterWrapper>
          <Icon name={iconAfter} color="PrimeWhite" size={30} isBlock />
        </IconAfterWrapper>
      ) : null}
    </Wrapper>
  );
}
