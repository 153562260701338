import { ThemeMode } from "@/lib/theme/schema";
import { useInterfacesTheme } from "@/lib/theme/ThemeProvider";
import { isPublishedPageHost } from "@/utils/isPublishedPageHost";
import { Alert } from "@zapier/design-system";
import { X } from "lucide-react";
import { useEffect } from "react";
import {
  Toaster as Sonner,
  toast as sonnerToast,
  type ToasterProps,
  type ToastT,
} from "sonner";

type Props = { duration?: number; position?: ToasterProps["position"] };

export const Toaster = ({
  duration = 5_000,
  position = "bottom-center",
}: Props) => {
  const interfacesTheme = useInterfacesTheme();
  const sonnerTheme: ToasterProps["theme"] =
    (interfacesTheme?.mode?.toLowerCase() as
      | Lowercase<ThemeMode>
      | null
      | undefined) ?? "system";

  /**
   * We have no way to wrap the "JSX" produced by the ".error" and ".success" methods with our custom div
   * that we could apply `data-testid` to.
   *
   * To ensure we can easily query the toasts in tests, we use `MutationObserver` to append this missing data attribute.
   */
  useEffect(() => {
    const observer = new MutationObserver(() => {
      document.querySelectorAll("[data-sonner-toast]").forEach((toast) => {
        const toastType = toast.getAttribute("data-type");
        if (!toastType) {
          return;
        }

        if (toastType === "success") {
          toast.setAttribute("data-testid", "toast-success-notification");
        }

        if (toastType === "error") {
          toast.setAttribute("data-testid", "toast-error-notification");
        }
      });
    });

    const notificationsContainer = document.querySelector(
      "section[aria-label~='Notifications']"
    );

    if (!notificationsContainer) {
      return;
    }

    observer.observe(notificationsContainer, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Sonner
      theme={sonnerTheme}
      className="toaster group"
      containerAriaLabel="Notifications"
      richColors={true}
      duration={duration}
      position={position}
      toastOptions={{
        classNames: {
          toast:
            "group toast group-[.toaster]:bg-card group-[.toaster]:text-card-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg",
          description: "group-[.toast]:text-muted-foreground",
          actionButton:
            "group-[.toast]:bg-primary group-[.toast]:text-primary-foreground",
          cancelButton:
            "group-[.toast]:bg-muted group-[.toast]:text-muted-foreground",
        },
      }}
    />
  );
};

type ToastOptions = {
  duration?: ToastT["duration"];
  position?: ToastT["position"];
  id?: ToastT["id"];
};

export const toast = {
  success: (
    { message }: { message: React.ReactNode },
    options?: ToastOptions
  ) => {
    /**
     * We display toast when TRPC calls fail.
     * For the published page, we want to display different toast than for the builder (and other pages).
     * To achieve that, we leverage the fact that published pages have different hosts than builder/other pages in our application.
     */
    if (isPublishedPageHost(window.location.host)) {
      return publishedPageSuccessToast({ message, options });
    }

    return defaultSuccessToast({ message, options });
  },
  error: (
    { message }: { message: React.ReactNode },
    options?: ToastOptions
  ) => {
    /**
     * We display toast when TRPC calls fail.
     * For the published page, we want to display different toast than for the builder (and other pages).
     * To achieve that, we leverage the fact that published pages have different hosts than builder/other pages in our application.
     */
    if (isPublishedPageHost(window.location.host)) {
      return publishedPageErrorToast({ message, options });
    }

    return defaultErrorToast({ message, options });
  },
};

export function publishedPageSuccessToast({
  message,
  options,
}: {
  message: React.ReactNode;
  options?: ToastOptions;
}) {
  sonnerToast.success(
    // This is a workaround because sonner won't show the cancel button if the toast is a JSX element
    // We need the wrapper on the message to add the test id for e2e tests
    // We can remove this once sonner fixes the issue
    // https://github.com/emilkowalski/sonner/issues/445
    () => <div>{message}</div>,
    {
      cancel: {
        label: <X size={14} aria-label="Dismiss" />,
        // onClick is required but we don't want to do anything on toast dismissal
        onClick: () => {},
      },
      cancelButtonStyle: {
        backgroundColor: "transparent",
        color: "inherit",
      },
      ...options,
    }
  );
}

export function defaultSuccessToast({
  message,
  options,
}: {
  message: React.ReactNode;
  options?: ToastOptions;
}) {
  return sonnerToast.custom((toastId) => {
    return (
      <div data-testid="toast-success-notification">
        <Alert
          onDismiss={() => sonnerToast.dismiss(toastId)}
          title={message}
          aria-live={"polite"}
          status="success"
          variant={"toast"}
        />
      </div>
    );
  }, options);
}

export function publishedPageErrorToast({
  message,
  options,
}: {
  message: React.ReactNode;
  options?: ToastOptions;
}) {
  return sonnerToast.error(() => <div>{message}</div>, {
    cancel: {
      label: <X size={14} aria-label="Dismiss" />,
      // onClick is required but we don't want to do anything on toast dismissal
      onClick: () => {},
    },
    cancelButtonStyle: {
      backgroundColor: "transparent",
      color: "inherit",
    },
    ...options,
  });
}

export function defaultErrorToast({
  message,
  options,
}: {
  message: React.ReactNode;
  options?: ToastOptions;
}) {
  return sonnerToast.custom((toastId) => {
    return (
      <div data-testid="toast-error-notification">
        <Alert
          aria-live={"assertive"}
          onDismiss={() => sonnerToast.dismiss(toastId)}
          title={message}
          status="error"
          variant={"toast"}
        />
      </div>
    );
  }, options);
}
