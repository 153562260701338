import { isEqual, isObject, isString } from "lodash";
import { KanbanRenderData, KanbanRenderDataCard } from "../constants-and-types";
import { DropResult } from "@hello-pangea/dnd";
import { useEffect, useState } from "react";
import * as immutable from "object-path-immutable";
import { InteractButton } from "block-system/components/InteractButton";
import Skeleton from "react-loading-skeleton";
import { Kanban } from "./Kanban";

export type MoveCard = (args: {
  movingCardId: string;
  targetCardId: string | undefined;
  placeAfter: boolean;
  toValue: string | undefined;
}) => void;

export function TableBackedKanban(props: {
  data: KanbanRenderData;
  accountId?: number;
  tableId?: string;
  onMoveCard?: MoveCard;
  onClickCard?: (cardId: string) => void;
  onClickCreate?: (columnValue: string) => void;
  disableScroll?: boolean;
  isDisabled?: boolean;
  isSkeleton?: boolean;
  isEditing?: boolean;
  newCardButtonCopy?: string;
}) {
  const [data, setData] = useState(props.data);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const handleDragEnd = (result: DropResult) => {
    if (!props.onMoveCard) return;

    const { source, destination } = result;

    if (
      !destination || // dropped outside the list
      isEqual(source, destination) // didn't move
    ) {
      return;
    }

    // Optimistically reorder the card in state
    const newData = moveCardInRenderData(data, source, destination);
    setData(newData);

    // Get target card
    const [targetCardId, placeAfter] = getTargetCardId(
      data,
      source,
      destination
    );

    // Mutate
    props.onMoveCard({
      movingCardId: result.draggableId,
      targetCardId,
      placeAfter,
      toValue: destination.droppableId,
    });
  };

  return (
    <Kanban
      onDragEnd={handleDragEnd}
      disableScroll={props.isSkeleton || props.disableScroll}
    >
      {data.map((column) => {
        return (
          <Kanban.Column
            key={column.id}
            droppableId={column.id}
            isDropDisabled={props.isSkeleton || props.isDisabled}
            disableScroll={props.isSkeleton || !!props.disableScroll}
            onClickCreate={
              props.onClickCreate
                ? () => props.onClickCreate?.(column.id)
                : undefined
            }
            newCardButtonCopy={props.newCardButtonCopy}
            newCardButtonAlwaysVisible={
              props.isEditing || column.cards.length === 0
            }
            title={props.isSkeleton ? <Skeleton width={100} /> : column.label}
          >
            {column.cards.map((card, i) => {
              return (
                <Kanban.Card
                  key={card.id}
                  draggableId={card.id}
                  isDragDisabled={
                    props.isSkeleton || !props.onMoveCard || props.isDisabled
                  }
                  index={i}
                  onClick={
                    props.onClickCard && !props.isSkeleton
                      ? () => {
                          props.onClickCard?.(card.id);
                        }
                      : undefined
                  }
                >
                  <Kanban.CardTitle>
                    {props.isSkeleton ? <Skeleton width={150} /> : card.title}
                  </Kanban.CardTitle>

                  {props.isSkeleton ? (
                    <Skeleton count={2} width="100%" />
                  ) : (
                    <>
                      <KanbanCardDescription card={card} />

                      <KanbanCardInteractButton
                        card={card}
                        tableId={props.tableId}
                        isDisabled={props.isDisabled}
                      />

                      <KanbanCardDescriptionLabel
                        card={card}
                        tableId={props.tableId}
                      />
                    </>
                  )}
                </Kanban.Card>
              );
            })}
          </Kanban.Column>
        );
      })}
    </Kanban>
  );
}

function KanbanCardDescription({ card }: { card: KanbanRenderDataCard }) {
  if (!isString(card.description)) {
    return null;
  }

  if (card.description.trim() === "") {
    return null;
  }

  return <Kanban.CardBody>{card.description}</Kanban.CardBody>;
}

function KanbanCardInteractButton({
  tableId,
  card,
  isDisabled,
}: {
  tableId: string | undefined;
  card: KanbanRenderDataCard;
  isDisabled?: boolean;
}) {
  if (!isObject(card.description)) {
    return null;
  }

  if (
    !["button_continue_zap", "button_trigger_zap"].includes(
      card.descriptionFieldType
    )
  ) {
    return null;
  }

  if (!card.description) {
    return null;
  }

  if (!("label" in card.description)) {
    return null;
  }

  if (!tableId) {
    return null;
  }

  return (
    <Kanban.CardBody>
      <InteractButton
        tableId={tableId}
        fieldId={card.description.fieldId}
        recordId={card.description.recordId}
        isEnabled={card.description?.is_enabled}
        isDisabled={isDisabled}
        disableOnClick={!!card.description?.disable_on_click}
      >
        {card.description.label}
      </InteractButton>
    </Kanban.CardBody>
  );
}

function KanbanCardDescriptionLabel({
  tableId,
  card,
}: {
  tableId: string | undefined;
  card: KanbanRenderDataCard;
}) {
  if (!isObject(card.description)) {
    return null;
  }

  if (card.descriptionFieldType !== "labeled_string") {
    return null;
  }

  if (!("label" in card.description)) {
    return null;
  }

  if (!tableId) {
    return null;
  }

  return <Kanban.CardBody>{card.description.label}</Kanban.CardBody>;
}

function getTargetCardId(
  data: KanbanRenderData,
  source: DropResult["source"],
  destination: Exclude<DropResult["destination"], null>
): [KanbanRenderDataCard["id"] | undefined, boolean] {
  const {
    droppableId: destinationColumnKey,
    index: originalDestinationCardIndex,
  } = destination;
  const { droppableId: sourceColumnKey, index: sourceCardIndex } = source;

  const destinationColumn = data.find(
    ({ id: columnKey }) => columnKey === destinationColumnKey
  );

  if (!destinationColumn || !destinationColumn.cards?.length) {
    return [undefined, false];
  }

  // determine the correct position for the source card within the kanban board
  let placeAfter = false;
  let destinationCardIndex = originalDestinationCardIndex;
  const { cards: destinationCards } = destinationColumn;

  // if the source card is being moved within its current column, determine whether to place it before or after its destination card
  if (sourceColumnKey === destinationColumnKey) {
    // if the source card is moved down the column, place it after its destination card
    placeAfter = sourceCardIndex < originalDestinationCardIndex;
  }
  // if the source card is being moved to another column with only one card, place it after the existing card (unless it is already at the top)
  else if (destinationCards.length === 1) {
    placeAfter = destinationCardIndex !== 0; // if the original destination was not the top of the column, place the source card after it
    destinationCardIndex = 0; // set the destination card index to the top of the column
  }
  // for all other moves that are not lateral or to the top of another column, fix the destination card and place the source card after it
  else if (
    destinationCardIndex !== 0 &&
    sourceCardIndex !== destinationCardIndex
  ) {
    destinationCardIndex -= 1; // decrement the destination card index to account for the position of the source card
    placeAfter = true; // place the source card after the destination card
  }

  const { id: destinationCardId } = destinationCards[destinationCardIndex];

  return [destinationCardId, placeAfter];
}

function moveCardInRenderData(
  data: KanbanRenderData,
  source: DropResult["source"],
  destination: Exclude<DropResult["destination"], null>
): KanbanRenderData {
  const sourceColumnIndex = data.findIndex(
    (column) => column.id === source.droppableId
  );
  const destinationColumnIndex = data.findIndex(
    (column) => column.id === destination.droppableId
  );

  const card = data[sourceColumnIndex].cards[source.index];

  let newData = data;
  newData = immutable.del(newData, [sourceColumnIndex, "cards", source.index]);
  newData = immutable.insert(
    newData,
    [destinationColumnIndex, "cards"],
    card,
    destination.index
  );

  return newData;
}
