import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/block-system/brickz/lib/utils";
import { Loader2 as Spinner } from "lucide-react";

export type ButtonVariant =
  | "default"
  | "destructive"
  | "outline"
  | "secondary"
  | "ghost"
  | "link";

export type ButtonSize = "small" | "medium" | "large" | "icon";

const buttonVariants = cva<{
  variant: Record<ButtonVariant, string>;
  size: Record<ButtonSize, string>;
}>(
  "relative inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0",
  {
    variants: {
      variant: {
        /**
         * The colors for disabled are hardcoded.
         * This is a deliberate design decision we made.
         */
        default:
          "bg-primary text-primary-foreground hover:bg-primary/90 hover:text-primary-foreground disabled:bg-[#D6D6D6] disabled:text-[#828282]",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90 disabled:bg-[#D6D6D6] disabled:text-[#828282]",
        outline:
          "border border-solid border-border bg-card text-card-foreground hover:bg-secondary hover:text-secondary-foreground disabled:bg-[#D6D6D6] disabled:text-[#828282]",
        secondary:
          "hover:text-shade-900 bg-secondary text-secondary-foreground hover:bg-secondary/90 disabled:bg-[#D6D6D6] disabled:text-[#828282]",
        ghost:
          "bg-transparent text-secondary-foreground hover:bg-secondary hover:text-secondary-foreground disabled:bg-[#D6D6D6] disabled:text-[#828282]",
        link: "bg-transparent text-foreground hover:bg-transparent hover:underline disabled:opacity-50",
      },
      size: {
        medium: "h-10 min-h-10 px-4 py-2",
        small: "h-9 min-h-9 rounded-md px-3",
        large: "h-11 min-h-11 rounded-md px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "medium",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  isLoading?: boolean;
  asChild?: boolean;
  loadingText?: React.ReactNode;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      isLoading,
      loadingText = "Loading",
      children,
      asChild = false,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";

    const renderLoadingText = loadingText != null && size != "icon";

    return (
      <Comp
        className={cn(
          buttonVariants({
            variant,
            size,
            className: cn(
              {
                "bg-slate-900/50 text-white hover:bg-slate-900/50 hover:text-white hover:no-underline disabled:bg-slate-900/50 disabled:text-white disabled:opacity-100":
                  isLoading,
              },
              "grid grid-cols-1 grid-rows-1",
              className
            ),
          })
        )}
        ref={ref}
        {...props}
      >
        <div
          className={cn("col-span-1 col-start-1 row-span-1 row-start-1", {
            "opacity-0": isLoading,
          })}
        >
          {children}
        </div>
        {isLoading ? (
          <div className={cn("col-span-1 col-start-1 row-span-1 row-start-1")}>
            <div
              data-testid="spinner"
              className="flex items-center justify-center gap-2"
            >
              <Spinner className="animate-spin" width={16} height={16} />
              {renderLoadingText ? <span>{loadingText}</span> : null}
            </div>
          </div>
        ) : null}
      </Comp>
    );
  }
);

Button.displayName = "Button";

export { Button, buttonVariants };
