import { useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { trpc } from "utils/trpc";

import { Spacer } from "@zapier/design-system";

import { Button } from "@/block-system/brickz/components/ui/Button";
import { Field } from "@/block-system/brickz/components/ui/Field";
import { TextInput } from "@/block-system/brickz/components/ui/TextInput";
import { FormContainer } from "@/block-system/components/forms/FormContainer";
import { ReCAPTCHA } from "components/ReCAPTCHA";
import { Content } from "./";

type FormData = { password: string; captcha: string };

export function ProjectPasswordAuthForm(props: {
  projectId: string;
  onAuthSuccess: () => void;
  captchaEnabled: boolean;
  isEditing: boolean;
}) {
  const { mutateAsync: checkPassword } =
    trpc.projectAuth.checkPassword.useMutation();

  const [redirectingIsLoading, setRedirectingIsLoading] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { isSubmitting: formIsSubmitting, errors },
  } = useForm<FormData>();

  const onSubmit = async ({ password, captcha }: FormData) => {
    const { success } = await checkPassword({
      projectId: props.projectId,
      password,
      captcha,
    });

    if (success) {
      setRedirectingIsLoading(true);
      props.onAuthSuccess();
    } else {
      setError(
        "password",
        { message: "Invalid password" },
        { shouldFocus: true }
      );
      setValue("password", "");
    }
  };

  const isLoading = formIsSubmitting || redirectingIsLoading;

  return (
    <Content>
      <FormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="password"
            control={control}
            rules={{
              required: "Password is required",
            }}
            render={({ field }) => (
              <Field
                label="Enter password to log in"
                renderInput={(inputProps) => (
                  <TextInput
                    {...inputProps}
                    {...field}
                    type="password"
                    autoFocus={!props.isEditing}
                    placeholder="Password"
                    tabIndex={props.isEditing ? -1 : undefined}
                  />
                )}
                error={errors.password?.message}
              />
            )}
          />
          {props.captchaEnabled ? (
            <>
              <Spacer height={20} />
              <Controller
                name="captcha"
                control={control}
                render={() => (
                  <Field
                    error={errors.captcha?.message?.toString()}
                    label="Captcha"
                    renderInput={() => (
                      <ReCAPTCHA
                        action="PROJECT_LOGIN"
                        onChange={(key) => {
                          setValue("captcha", key);
                        }}
                      />
                    )}
                  />
                )}
                rules={{
                  required: "Captcha is required",
                }}
              />
            </>
          ) : null}
          <Spacer height={30} />
          <Button
            type="submit"
            className="w-full"
            isLoading={isLoading}
            tabIndex={props.isEditing ? -1 : undefined}
          >
            Log in
          </Button>
        </form>
      </FormContainer>
    </Content>
  );
}
