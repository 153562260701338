import { pick } from "lodash";
import type { InterfacesTheme } from "../../schema";
import { presetThemesByTitle } from "./presetThemes";
import { brandedTheme } from "./brandedTheme";

export const ShadcnLightColorNames = [
  "background",
  "foreground",
  "muted",
  "mutedForeground",
  "card",
  "cardForeground",
  "popover",
  "popoverForeground",
  "border",
  "input",
  "primary",
  "primaryForeground",
  "secondary",
  "secondaryForeground",
  "accent",
  "accentForeground",
  "destructive",
  "destructiveForeground",
  "ring",
  "inputField",
  "inputFieldForeground",
] as const;

export const ShadcnDarkColorNames = [
  "darkBackground",
  "darkForeground",
  "darkCard",
  "darkCardForeground",
  "darkPopover",
  "darkPopoverForeground",
  "darkPrimary",
  "darkPrimaryForeground",
  "darkSecondary",
  "darkSecondaryForeground",
  "darkMuted",
  "darkMutedForeground",
  "darkAccent",
  "darkAccentForeground",
  "darkDestructive",
  "darkDestructiveForeground",
  "darkBorder",
  "darkInput",
  "darkRing",
  "darkInputField",
  "darkInputFieldForeground",
] as const;

export const ShadcnColorNames = [
  ...ShadcnLightColorNames,
  ...ShadcnDarkColorNames,
] as const;

export const ColorNames = [...ShadcnColorNames, "brandColor"] as const;

export const NonColorNames = [
  "darkModeEnabled",
  "radius",
  "preset",
  "mode",
] as const;

export type ShadcnLightColorName = (typeof ShadcnLightColorNames)[number];
export type ShadcnDarkColorName = (typeof ShadcnDarkColorNames)[number];
export type ShadcnColorName = (typeof ShadcnColorNames)[number];
export type ColorName = (typeof ColorNames)[number];
export type NonColorName = (typeof NonColorNames)[number];

export function extractShadcnColors(
  theme: InterfacesTheme
): Pick<InterfacesTheme, ShadcnColorName> {
  return pick(theme, ShadcnColorNames);
}

export function extractColors(
  theme: InterfacesTheme
): Pick<InterfacesTheme, ColorName> {
  return pick(theme, ColorNames);
}

export function extractNonColors(
  theme: InterfacesTheme
): Pick<InterfacesTheme, NonColorName> {
  return pick(theme, NonColorNames);
}

/**
 * Takes a theme and applies any color overrides based on its preset value
 * @param providedTheme - The theme to apply preset overrides to
 * @returns A new theme with preset color overrides applied if a preset is specified,
 *          otherwise returns the original theme unchanged
 * @throws {AppError} If the specified preset is not found
 */
export function getThemeWithPresetOverrides(
  providedTheme: InterfacesTheme
): InterfacesTheme {
  const { preset } = providedTheme;

  if (preset == null || preset === "Custom") {
    return providedTheme;
  }

  let colorOverrides: Pick<InterfacesTheme, ColorName>;

  if (preset === "Brand") {
    colorOverrides = extractColors(brandedTheme(providedTheme.brandColor));
  } else {
    const presetTheme = presetThemesByTitle[preset];
    colorOverrides = extractColors(presetTheme.theme);
  }

  return {
    ...providedTheme,
    ...colorOverrides,
  };
}
