import { EmbedBlock } from "../types";
import { useProjectTheme } from "lib/hooks/useProjectTheme";
import { useEffect, useState } from "react";
import { StandardBlockZeroState } from "@/block-system/components/BlockErrors/StandardBlockZeroState";
import { Code } from "lucide-react";

const baseIframeAttributes = {
  /**
   * `allow-scripts` -> Enabled to make Google Calendar and YouTube work.
   * `allow-same-origin` -> Enabled to make Google Calendar and YouTube work.
   *
   * ---
   * `allow-popups` -> Enabled to make Google Calendar work.
   * `allow-forms` -> Enabled to make Google Calendar work.
   *
   * These two are needed when a user is not authenticated into Google.
   * The calendar widget will display a login screen that uses a popup and a form to authenticate the user.
   * ---
   *
   */
  sandbox: "allow-scripts allow-forms allow-same-origin allow-popups",
  "data-testid": `embed-block`,
  className: "m-auto",
};

export const Embed = (props: EmbedBlock["config"]) => {
  const isEmbeddedWithinItself = useIsEmbeddedWithinItself();
  if (isEmbeddedWithinItself) {
    return <div data-testid={"embed-block-loop"} />;
  }

  const source = props.source;
  switch (source) {
    case "url":
      return <UrlSourceIframe {...props} />;
    case "code":
      return <CodeSourceIframe {...props} />;
    default:
      const _exhaustiveCheck: never = source;
      return _exhaustiveCheck;
  }
};

function UrlSourceIframe({
  url,
  source,
  title,
  height,
  width,
}: EmbedBlock["config"]) {
  const theme = useProjectTheme();

  if (!url) {
    return <EmbedZeroState source={source} />;
  }

  return (
    <iframe
      {...baseIframeAttributes}
      title={title}
      src={url}
      height={height}
      width={theme.app.width[width]}
    />
  );
}

function CodeSourceIframe({
  code,
  source,
  width,
  height,
}: EmbedBlock["config"]) {
  const theme = useProjectTheme();

  if (!code) {
    return <EmbedZeroState source={source} />;
  }

  return (
    <iframe
      {...baseIframeAttributes}
      srcDoc={code}
      width={theme.app.width[width]}
      height={height}
    />
  );
}

function EmbedZeroState({
  source,
}: {
  source: EmbedBlock["config"]["source"];
}) {
  const zeroStateText = source === "url" ? "URL" : "HTML code";

  return (
    <div data-testid={"embed-block-zero-state"}>
      <StandardBlockZeroState
        icon={<Code size={32} />}
        text={`Add ${zeroStateText} to display content here`}
      />
    </div>
  );
}

/**
 * In an ideal world, we could use negation in CSP headers.
 * '!self'
 *
 * Sadly, this is not possible.
 *
 * To ensure we do not allow for "loops" of embedding, we need to check if the current window is embedded within itself.
 * See https://developer.mozilla.org/en-US/docs/Web/API/Window/frameElement
 *
 * We decided to omit the backend validation as we deemed this sufficient to prevent loops.
 */
function useIsEmbeddedWithinItself() {
  const [isEmbeddedWithinItself, setIsEmbeddedWithinItself] = useState(false);
  useEffect(() => {
    setIsEmbeddedWithinItself(window.frameElement != null);
  }, []);

  return isEmbeddedWithinItself;
}
