import { cn } from "@/block-system/brickz/lib/utils";
import { ComponentBlockStyle } from "@/block-system/types";

export type LinksProps = {
  title?: string;
  children: React.ReactNode;
  width: ComponentBlockStyle["width"];
  isEditing: boolean;
};

export function Links({
  title,
  width = "medium",
  isEditing,
  children,
}: LinksProps) {
  return (
    <section className="flex flex-col gap-5 @container">
      {title ? (
        <h2 className="text-3xl font-extrabold text-foreground">{title}</h2>
      ) : null}
      <div
        className={cn(
          "grid w-full grid-cols-[(1,minmax(248px,368px))] justify-center gap-5 @narrow:grid-cols-[repeat(2,minmax(248px,368px))] @medium:grid-cols-[repeat(3,minmax(248px,368px))]",
          {
            "ml-[-10px]": isEditing,
            //  * The "full" operates on the percentage width. That is problematic for container queries as we need concrete width in the container query.
            //  * Since full should be bigger than wide, we can apply @wide styles to full.
            "@wide:gap-8": width === "full",
          }
        )}
      >
        {children}
      </div>
    </section>
  );
}
