import "zod-openapi/extend";

import { z } from "zod";
import { getTailwindHsl } from "uicolors-generator";

const RemSchema = z.string().regex(/^\d+(?:\.\d+)?rem$/i);

const HexSchema = z
  .string()
  .regex(/^#?([0-9A-F]{3}|[0-9A-F]{4}|[0-9A-F]{6}|[0-9A-F]{8})$/i)
  .transform((value) => value.toUpperCase())
  .openapi({ effectType: "input" });

const HexToHslSchema = HexSchema.transform(getTailwindHsl);

// Keep this in sync with the ThemePreset enum in prisma/schema.prisma
export const ThemePresetSchema = z.enum([
  "Default",
  "Black",
  "Purple",
  "Green",
  "Red",
  "Orange",
  "Brand",
  "Custom",
]);

export type ThemePreset = z.infer<typeof ThemePresetSchema>;

// Keep this in sync with the ThemeMode enum in prisma/schema.prisma
export const ThemeModeSchema = z.enum(["Light", "Dark", "System"]);

export type ThemeMode = z.infer<typeof ThemeModeSchema>;

export const PreviewModeSchema = ThemeModeSchema.exclude(["System"]);

export type PreviewMode = z.infer<typeof PreviewModeSchema>;

export const InterfacesThemeSchema = z
  .object({
    preset: ThemePresetSchema.nullish(),
    mode: ThemeModeSchema.nullish().default(ThemeModeSchema.enum.Light),

    darkModeEnabled: z.boolean().default(false),

    radius: RemSchema.default("0.5rem"),

    brandColor: HexSchema.default("#3498DB"),

    background: HexSchema.default("#FFFFFF"),
    foreground: HexSchema.default("#2C3E50"),
    /**
     * Changing the `card` value here, might result in custom-theme.e2e.ts tests
     * failing, because a hardcoded RGB value is used in the tests.
     */
    card: HexSchema.default("#FFFFFF"),
    cardForeground: HexSchema.default("#2C3E50"),
    popover: HexSchema.default("#FFFFFF"),
    popoverForeground: HexSchema.default("#2C3E50"),
    primary: HexSchema.default("#3498DB"),
    primaryForeground: HexSchema.default("#FFFFFF"),
    secondary: HexSchema.default("#F5F7FB"),
    secondaryForeground: HexSchema.default("#2C3E50"),
    muted: HexSchema.default("#F7F9FC"),
    mutedForeground: HexSchema.default("#7F8C8D"),
    accent: HexSchema.default("#F1F5F9"),
    accentForeground: HexSchema.default("#0F172A"),
    destructive: HexSchema.default("#DC2626"),
    destructiveForeground: HexSchema.default("#FFFFFF"),
    border: HexSchema.default("#D9DDE4"),
    input: HexSchema.default("#CBCFD6"),
    ring: HexSchema.default("#3498DB"),
    inputField: HexSchema.default("#FFFFFF"),
    inputFieldForeground: HexSchema.default("#2C3E50"),

    darkBackground: HexSchema.default("#000000"),
    darkForeground: HexSchema.default("#F8FAFC"),
    darkCard: HexSchema.default("#1A1A1A"),
    darkCardForeground: HexSchema.default("#F8FAFC"),
    darkPopover: HexSchema.default("#1A1A1A"),
    darkPopoverForeground: HexSchema.default("#F8FAFC"),
    darkPrimary: HexSchema.default("#3B82F6"),
    darkPrimaryForeground: HexSchema.default("#F8FAFC"),
    darkSecondary: HexSchema.default("#1E293B"),
    darkSecondaryForeground: HexSchema.default("#F8FAFC"),
    darkMuted: HexSchema.default("#1E293B"),
    darkMutedForeground: HexSchema.default("#94A3B8"),
    darkAccent: HexSchema.default("#1E293B"),
    darkAccentForeground: HexSchema.default("#F8FAFC"),
    darkDestructive: HexSchema.default("#DC2626"),
    darkDestructiveForeground: HexSchema.default("#F8FAFC"),
    darkBorder: HexSchema.default("#1E293B"),
    darkInput: HexSchema.default("#1E293B"),
    darkRing: HexSchema.default("#94A3B8"),
    darkInputField: HexSchema.default("#1A1A1A"),
    darkInputFieldForeground: HexSchema.default("#F8FAFC"),
  })
  .openapi({ ref: "InterfacesTheme" });

export type InterfacesTheme = z.infer<typeof InterfacesThemeSchema>;

export const HslThemeSchema = z.object({
  mode: ThemeModeSchema.default(ThemeModeSchema.enum.Light),

  brandColor: HexToHslSchema,
  radius: RemSchema,

  background: HexToHslSchema,
  foreground: HexToHslSchema,
  card: HexToHslSchema,
  cardForeground: HexToHslSchema,
  popover: HexToHslSchema,
  popoverForeground: HexToHslSchema,
  primary: HexToHslSchema,
  primaryForeground: HexToHslSchema,
  secondary: HexToHslSchema,
  secondaryForeground: HexToHslSchema,
  muted: HexToHslSchema,
  mutedForeground: HexToHslSchema,
  accent: HexToHslSchema,
  accentForeground: HexToHslSchema,
  destructive: HexToHslSchema,
  destructiveForeground: HexToHslSchema,
  border: HexToHslSchema,
  input: HexToHslSchema,
  ring: HexToHslSchema,
  inputField: HexToHslSchema,
  inputFieldForeground: HexToHslSchema,

  darkBackground: HexToHslSchema,
  darkForeground: HexToHslSchema,
  darkCard: HexToHslSchema,
  darkCardForeground: HexToHslSchema,
  darkPopover: HexToHslSchema,
  darkPopoverForeground: HexToHslSchema,
  darkPrimary: HexToHslSchema,
  darkPrimaryForeground: HexToHslSchema,
  darkSecondary: HexToHslSchema,
  darkSecondaryForeground: HexToHslSchema,
  darkMuted: HexToHslSchema,
  darkMutedForeground: HexToHslSchema,
  darkAccent: HexToHslSchema,
  darkAccentForeground: HexToHslSchema,
  darkDestructive: HexToHslSchema,
  darkDestructiveForeground: HexToHslSchema,
  darkBorder: HexToHslSchema,
  darkInput: HexToHslSchema,
  darkRing: HexToHslSchema,
  darkInputField: HexToHslSchema,
  darkInputFieldForeground: HexToHslSchema,
});

export type HslTheme = z.infer<typeof HslThemeSchema>;
