import { hsla, parseToHsla, toHex } from "color2k";

/**
 * Adjusts an HSL component (saturation or lightness) by a percentage. This
 * function is reverse engineered from https://coolors.co/.
 *
 * @param currentValue - The current value of the HSL component (saturation or
 * lightness) to adjust. (0-1)
 * @param adjustment - The percentage to adjust the HSL component by. (-100-100)
 * @returns The adjusted value of the HSL component. (0-1)
 */
export function adjustHslComponent(
  currentValue: number,
  adjustment: number
): number {
  if (adjustment < -100 || adjustment > 100) {
    throw new Error("Adjustment must be between -100 and 100.");
  }
  if (currentValue < 0 || currentValue > 1) {
    throw new Error("Value must be between 0 and 1.");
  }

  if (adjustment === 0) {
    return currentValue;
  }

  const targetBound = adjustment > 0 ? 1 : 0;
  const scalingFactor = Math.abs(adjustment) / 100;

  return currentValue + (targetBound - currentValue) * scalingFactor;
}

export function adjustColor(
  color: string,
  { saturation, lightness }: { saturation?: number; lightness?: number }
) {
  const [h, s, l, a] = parseToHsla(color);
  const adjustedSaturation =
    saturation !== undefined ? adjustHslComponent(s, saturation) : s;
  const adjustedLightness =
    lightness !== undefined ? adjustHslComponent(l, lightness) : l;
  return toHex(hsla(h, adjustedSaturation, adjustedLightness, a)).toUpperCase();
}

export function standardDesaturation(color: string) {
  return adjustColor(color, { saturation: -60 });
}
