import {
  Colors,
  Modal,
  ModalContent,
  ModalContentBody,
  ModalContentFooter,
  Text,
} from "@zapier/design-system";
import { IconStripe } from "block-system/components/icons/IconStripe";
import { Button } from "components/Button";
import { styled } from "lib/theme";
import { ReactNode } from "react";
import { format as formatDate } from "date-fns";

export type StripeTermsMetadata = {
  version: string;
  url: string;
  date: Date;
};

export type StripeTerms = StripeTermsMetadata & {
  title: string;
  content: ReactNode;
};

const TermsWrapper = styled.div`
  p {
    margin-bottom: 16px;
  }
`;

const ListWrapper = styled.ol<{
  listStyle: "decimal" | "lower-alpha" | "lower-roman";
}>`
  list-style: ${({ listStyle }) => listStyle};
`;
const ListItem = styled.li`
  margin-left: 18px;
  margin-bottom: 16px;
`;
const TermsListItemTitle = styled.h4`
  font-weight: bold;
`;

export const STRIPE_TERMS: StripeTerms = {
  version: "1.0.0",
  url: "https://zapier-interfaces.zapier.app/stripe-payment-terms-v1-0-0",
  date: new Date("2024-09-12"),
  title: "Stripe Services Agreement",
  content: (
    <TermsWrapper data-testid="stripe-service-terms">
      <p>
        Stripe Payment is an optional feature of Zapier Interfaces that you may
        choose to use at your sole discretion. Stripe Payment allows you to
        provide or sell products and services (“Offerings”) to, and/or collect
        payments from your end users (together, “Interfaces Transactions”). By
        using Stripe Payment, you agree to the following:
      </p>
      <ListWrapper listStyle="decimal">
        <ListItem>
          <TermsListItemTitle>
            Responsibility for your Interfaces Transactions.
          </TermsListItemTitle>
          Zapier isn’t party to, and isn’t liable for Transactions or other
          related interactions between you and your end-users. Any Offerings
          provided through Transactions are your sole and exclusive
          responsibility, including without limitation, any damages or liability
          resulting from the Interfaces Transactions. You’re solely responsible
          for:
          <ListWrapper listStyle="lower-alpha">
            <ListItem>
              Taxes and other fees associated with your Interfaces Transactions,
              including without limitation any related to the purchase or sale
              of Offerings in connection with your Transactions;
            </ListItem>
            <ListItem>
              Collecting, reporting, and remitting required taxes to relevant
              government authorities;
            </ListItem>
            <ListItem>
              Informing your end users of required taxes, and providing them
              with invoices as required by applicable law;
            </ListItem>
            <ListItem>
              Monitoring distance sales thresholds and other indirect taxes
              (such as value-added tax or goods and services tax) and
              registration thresholds in countries where you have end users or
              ship goods or provide services;
            </ListItem>
            <ListItem>
              Registering for indirect Taxes in any countries where you must
              register. You also agree that any tax estimates, reporting or
              related materials that we may provide via the Platform are for
              illustration purposes only, and you may not rely on them to comply
              with your tax obligations. We do not give tax advice, and nothing
              we say should be interpreted as such;
            </ListItem>
            <ListItem>
              Fulfilling and delivering your products and services to your end
              users
            </ListItem>
            <ListItem>
              Any claims or warranties you make in connection with your
              Transactions and any claims made by end users against you; and
            </ListItem>
            <ListItem>
              Handling any comments or complaints related to your Transactions,
              including without limitation any issues related to payments,
              promotions, refunds or chargebacks. You agree to provide accurate
              and complete contact information on your sites so that your End
              Users can submit comments or questions to you.
            </ListItem>
          </ListWrapper>
        </ListItem>
        <ListItem>
          <TermsListItemTitle>Setting up Stripe Payment..</TermsListItemTitle>
          To accept payments from end users in connection with your Interfaces
          Transactions, create an Interface using the Stripe Checkout component
          and then connect your Zapier Account to your Stripe account. See more
          information here.
        </ListItem>
        <ListItem>
          <TermsListItemTitle>Your Relationship with Stripe</TermsListItemTitle>
          <ListWrapper listStyle="lower-alpha">
            <ListItem>
              Your relationship and account with Stripe is governed by Stripe’s
              terms, conditions, and policies (e.g., prohibited or restricted
              business activities).
            </ListItem>
            <ListItem>
              You agree not to conduct any Interfaces Transactions connected
              with any Prohibited and Restricted Businesses, which may vary
              based on jurisdiction.
            </ListItem>
            <ListItem>
              Zapier does not control and is not liable for any issues you have
              with Stripe or any transaction entered into with or through it
              (e.g., obtaining invoices for any transaction fees associated with
              your Interfaces Transactions).
            </ListItem>
            <ListItem>
              It is Stripe’s sole responsibility, and not Zapier’s, for
              providing support, maintenance, and technical assistance to you
              with respect to their services.
            </ListItem>
          </ListWrapper>
        </ListItem>
        <ListItem>
          <TermsListItemTitle>Fees</TermsListItemTitle>
          By using Stripe Payment in your Interfaces and completing Interfaces
          Transactions, you acknowledge and agree that certain fees may be
          charged to you by either Stripe directly or by Zapier. Those fees
          include:
          <ListWrapper listStyle="lower-alpha">
            <ListItem>
              A Stripe payment processing fee, as governed by your terms with
              Stripe.
            </ListItem>
            <ListItem>
              A Zapier technology fee, which is currently set at $0.00 USD per
              completed Interfaces Transaction.
            </ListItem>
          </ListWrapper>
        </ListItem>
        <ListItem>
          <TermsListItemTitle>
            Right to Modify or Discontinue Feature.
          </TermsListItemTitle>
          Zapier may, at any time and in our sole discretion and without any
          notice or obligation, modify suspend, disable, or remove Stripe
          Payment from Interfaces; and to the maximum extent permissible under
          applicable law, Zapier shall not be liable for any losses, damages, or
          liabilities you may incur as a result of Zapier taking any such
          action, including without limitation any loss of profits, data,
          goodwill or other intangible losses.
        </ListItem>
        <ListItem>
          <TermsListItemTitle>Updates to these Terms.</TermsListItemTitle>
          Zapier may update these terms governing Stripe Payment at any time.
          Your continued use of Stripe Payment constitutes your agreement to any
          such updates. If you do not agree to any update to these terms, you
          may discontinue use of the Stripe Payment feature at any time.
        </ListItem>
      </ListWrapper>
    </TermsWrapper>
  ),
};

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
`;

const StripeIconWrapper = styled.div`
  padding: 5px;
  border-radius: 5px;
  border: 1px solid ${Colors.GrayWarm4};
`;

const TermsMetaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
`;

function StripeServiceTerms({ terms }: { terms: typeof STRIPE_TERMS }) {
  return (
    <section>
      <HeaderWrapper>
        <StripeIconWrapper>
          <IconStripe size={30} />
        </StripeIconWrapper>
        <Text type="SectionHeader" tag={"h3"}>
          {terms.title}
        </Text>
        <TermsMetaWrapper>
          <Text type="MinimalPrint1" tag={"h3"}>
            Version: {terms.version}
          </Text>
          <Text type="MinimalPrint1" tag={"h3"}>
            Updated: {formatDate(terms.date, "yyyy-MM-dd")}
          </Text>
        </TermsMetaWrapper>
      </HeaderWrapper>
      <div>{terms.content}</div>
    </section>
  );
}

export function StripeTermsModalBody({
  terms,
  onAcceptTerms,
  onDeclineTerms,
}: {
  terms: StripeTerms;
  onAcceptTerms: VoidFunction;
  onDeclineTerms: VoidFunction;
}) {
  return (
    <>
      <ModalContentBody scrollable>
        <StripeServiceTerms terms={terms} />
      </ModalContentBody>
      <ModalContentFooter>
        <Button color="primary" onClick={() => onAcceptTerms()}>
          Accept
        </Button>
        <Button color="secondary" onClick={() => onDeclineTerms()}>
          Decline
        </Button>
      </ModalContentFooter>
    </>
  );
}

export function StripeTermsModal({
  onDeclineTerms,
  onAcceptTerms,
}: {
  onDeclineTerms: VoidFunction;
  onAcceptTerms: VoidFunction;
}) {
  return (
    <Modal
      aria-label={"Stripe Service Terms"}
      onClosed={() => {
        onDeclineTerms();
      }}
    >
      <ModalContent maxWidth={"60ch"}>
        <StripeTermsModalBody
          terms={STRIPE_TERMS}
          onDeclineTerms={onDeclineTerms}
          onAcceptTerms={onAcceptTerms}
        />
      </ModalContent>
    </Modal>
  );
}
