import { ButtonBlock } from "../types";
import { BUTTON_SIZE_STYLES, BUTTON_WIDTH_STYLES } from "../schema";
import { cn } from "@/block-system/brickz/lib/utils";

export function getButtonTailwindStyles(config: ButtonBlock["config"]) {
  return cn(
    "hover:shadow-md",
    {
      "rounded-small bg-primary text-primary-foreground hover:brightness-110":
        config.type === "primary",
      "rounded-small border-2 border-solid border-foreground bg-background text-foreground hover:brightness-110":
        config.type === "secondary",
    },
    BUTTON_SIZE_STYLES[config.size].padding,
    BUTTON_SIZE_STYLES[config.size].height,
    BUTTON_WIDTH_STYLES[config.width]
  );
}

function getCustomColorStyles(config: ButtonBlock["config"]) {
  if (config.colorType !== "custom") return undefined;
  return {
    backgroundColor: config.customColor?.background,
    color: config.customColor?.textColor,
  };
}

export function ButtonPresentational(
  props: ButtonBlock["config"] & {
    blockId: string;
    styles: string;
    handleButtonAction: () => void;
    disabled: boolean;
  }
) {
  return (
    <div
      style={{
        textAlign: props.alignment,
      }}
    >
      <button
        className={props.styles}
        style={getCustomColorStyles(props)}
        onClick={props.handleButtonAction}
        disabled={props.disabled}
        data-testid={`button-block-${props.blockId}`}
      >
        {props.label}
      </button>
    </div>
  );
}
