import type { MediaBlock } from "../types";
import { getProviderFromUrl, isValidVideoProviderUrl } from "../utils";
import ReactPlayer from "react-player";
import { Suspense } from "react";
import { LoomVideo } from "./LoomVideo";
import { Spinner } from "block-system/components/Spinner";

export function VideoBlock({ height, mediaUrl }: MediaBlock["config"]) {
  const validateUrl = () => {
    return mediaUrl && isValidVideoProviderUrl(mediaUrl);
  };

  const renderVideo = () => {
    if (!mediaUrl) return null;

    const provider = getProviderFromUrl(mediaUrl);
    switch (provider) {
      case "youtube":
      case "youtu.be":
      case "vimeo":
      case "zappy":
        return (
          <ReactPlayer
            url={mediaUrl}
            width={"100%"}
            height={height}
            fallback={<Spinner height={20} />}
            controls={provider === "zappy"}
            config={{
              youtube: {
                playerVars: {
                  controls: 1,
                },
              },
              vimeo: {
                playerOptions: {
                  controls: true,
                },
              },
            }}
          />
        );
      case "loom":
        return <LoomVideo url={mediaUrl} height={height} />;
      default:
        return null;
    }
  };

  return (
    <div className="w-full">
      {validateUrl() ? <Suspense>{renderVideo()}</Suspense> : null}
    </div>
  );
}
