import { Alignment } from "block-system/blocks/__shared__/components/AlignmentField/schema";
import { ReactNode } from "react";
import { LayoutItem } from "./LayoutItem";
import { cn } from "@/block-system/brickz/lib/utils";

type Props = {
  items: {
    child: ReactNode;
    alignment: Alignment;
    colspan: number;
  }[];
  columnCount: number;
};

export function LayoutParent({ items, columnCount }: Props) {
  return (
    <div
      className={cn("grid items-center gap-4 max-sm:grid-cols-1", {
        "grid-cols-2": columnCount === 2,
        "grid-cols-3": columnCount === 3,
      })}
    >
      {items.map((item, index) => (
        <LayoutItem item={item} key={index} />
      ))}
    </div>
  );
}
