import { Colors } from "@zapier/design-system";
import { SkeletonTheme as ReactSkeletonTheme } from "react-loading-skeleton";

export function SkeletonTheme({ children }: { children: React.ReactNode }) {
  return (
    <ReactSkeletonTheme
      baseColor={Colors.GrayWarm2}
      highlightColor={Colors.GrayWarm3}
    >
      {children}
    </ReactSkeletonTheme>
  );
}
