import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe, type Stripe } from "@stripe/stripe-js";
import { animateTransition } from "block-system/blocks/__shared__/transition";
import { StripeCheckoutSuccess } from "block-system/blocks/StripePayment/Block/ShadcnStripeCheckoutStatusModal";
import { useMemo, useState } from "react";
import { config } from "@/config";
import { cn } from "utils/cn";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/block-system/brickz/components/ui/dialog";

/**
 * This component is only used in in the "non-redirect" context.
 * Meaning, for the payment to complete, the user does not have to be redirected anywhere.
 * As such, the errors that can occur during the payment process are handled within the `EmbeddedCheckout` component.
 *
 * The `CheckoutModalState` mimics values from https://docs.stripe.com/api/checkout/sessions/object#checkout_session_object-status
 */
type CheckoutModalState = "open" | "complete";

export function StripeEmbeddedCheckoutModal({
  connectedAccountId,
  clientSecret,
  onClosed,
  onComplete,
}: {
  connectedAccountId: string;
  clientSecret: string;
  onClosed: () => void;
  onComplete: () => void;
}) {
  const [checkoutState, setCheckoutState] =
    useState<CheckoutModalState>("open");

  const stripePromise = useStripePromise({ connectedAccountId });

  return (
    <Dialog defaultOpen={true} modal={true} onOpenChange={() => onClosed()}>
      <DialogContent
        // To prevent auto focusing the close button
        onOpenAutoFocus={(e) => e.preventDefault()}
        className={cn(
          /**
           * This modal renders a custom form (the `EmbeddedCheckout` component) which styles we do not control.
           * Given the form renders with a white background, it is best to preserve that background for our wrapper.
           */
          "bg-white",
          "min-h-[200px]",
          "p-1",
          /**
           * The width values were hand-picked to make the form within the modal look relatively nice.
           */
          "w-[clamp(222px,100vw,560px)]",
          "overflow-auto",
          "border border-border shadow-md"
        )}
      >
        <DialogHeader className="sr-only">
          <DialogTitle>Checkout</DialogTitle>
          <DialogDescription>
            {checkoutState === "open"
              ? "Please complete your payment"
              : "Payment successful"}
          </DialogDescription>
        </DialogHeader>
        {checkoutState === "open" ? (
          <CheckingOut
            clientSecret={clientSecret}
            stripePromise={stripePromise}
            onComplete={() => {
              onComplete();
              animateTransition(() => {
                setCheckoutState("complete");
              });
            }}
          />
        ) : (
          <StripeCheckoutSuccess
            onClose={() => {
              animateTransition(() => {
                onClosed();
              });
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

function CheckingOut({
  stripePromise,
  onComplete,
  clientSecret,
}: {
  stripePromise: Promise<Stripe | null>;
  onComplete: () => void;
  clientSecret: string;
}) {
  return (
    <EmbeddedCheckoutProvider
      stripe={stripePromise}
      options={{
        onComplete: () => {
          onComplete();
        },
        clientSecret,
      }}
    >
      {/* Note that we can't style the `EmbeddedCheckout`, even when providing the `className` prop.
      The `EmbeddedCheckout` renders elements within the `iframe` and we can't "reach into" the iframe with CSS. */}
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  );
}

function useStripePromise({
  connectedAccountId,
}: {
  connectedAccountId: string;
}) {
  const stripePromise = useMemo(() => {
    return loadStripe(config().NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY, {
      stripeAccount: connectedAccountId,
    });
  }, [connectedAccountId]);

  return stripePromise;
}
