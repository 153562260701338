import {
  FileInfo,
  FileUpload as FileUploadType,
  FilesUpload as FilesUploadType,
  Widget,
  WidgetAPI,
} from "@uploadcare/react-widget";
import { forwardRef } from "react";

import type {
  UploadSignatureOutput,
  UploadStatusOutput_New,
} from "@/utils/trpc";

import { cn } from "@/block-system/brickz/lib/utils";
import { AlertCircle, CloudUpload } from "lucide-react";
import styles from "./FileUploadWidget.styles.module.css";
import { FileUploadPreview } from "./FileUploadPreview";
import { HelpText } from "./Field";

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      className={cn(
        "flex h-[80px] items-center justify-between",
        "rounded-md border border-dashed border-input",
        "px-[12px] py-[10px]",
        "focus-within:outline-none focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2 focus-within:ring-offset-background",
        styles.container
      )}
    >
      {children}
    </div>
  );
};

const Placeholder = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      data-testid={"multi-file-upload-placeholder"}
      className={cn(
        "flex shrink items-center gap-2",
        "text-sm text-card-foreground/60",
        styles.placeholder
      )}
    >
      {children}
    </div>
  );
};

type Props = {
  randomWidgetKey?: string | null;
  placeholder?: string;
  allowedFileTypes?: string;
  onChange: (value?: FileInfo) => void;
  maxFileSize?: number;
  blockId: string;
  isEditing?: boolean;
  publicKey: string;
  id: string;
  name: string;
  required: boolean;
  fileUploadsDisabled: boolean;
  uploadSignature: UploadSignatureOutput | undefined;
  uploadStatuses: UploadStatusOutput_New | undefined;
  isUploadStatusError: boolean;
  files: FileInfo[];
  onFileSelect?: (fileInfo: FileUploadType | FilesUploadType | null) => void;
  acceptTypes: string;
  widgetRef: React.RefObject<WidgetAPI>;
  fileExtensionValidator: (fileInfo: FileInfo) => void;
  fileSizeValidator: (fileInfo: FileInfo) => void;
  remainingFileCount?: number;
  onRemoveFile: (file: FileInfo) => void;
  helpText?: React.ReactNode;
};

type FileUploadWidgetProps = Omit<Props, "name" | "blockId" | "onChange">;

export const FileUploadWidget = forwardRef<
  HTMLDivElement,
  FileUploadWidgetProps
>((props, ref) => {
  const {
    randomWidgetKey,
    placeholder,
    fileUploadsDisabled,
    uploadSignature,
    uploadStatuses,
    isUploadStatusError,
    files,
    onFileSelect,
    acceptTypes,
    widgetRef,
    fileExtensionValidator,
    fileSizeValidator,
    publicKey,
    remainingFileCount,
    onRemoveFile,
    helpText,
    ...restProps
  } = props;

  if (fileUploadsDisabled) {
    return (
      <div className={cn("flex gap-2 rounded-md bg-background p-4")}>
        <div className={cn("flex-none text-primary")}>
          <AlertCircle size={24} />
        </div>
        <div className="flex-1">
          File uploads are temporarily disabled and will return shortly. We
          apologize for the inconvenience.
        </div>
      </div>
    );
  }

  if (!uploadSignature && !restProps.isEditing) {
    return (
      <Wrapper>
        <Placeholder>
          <CloudUpload size={16} />
          {placeholder || "Drag your files here"}
        </Placeholder>
      </Wrapper>
    );
  }
  const disabled = remainingFileCount !== undefined && remainingFileCount <= 0;
  const buttonText = "Browse files";
  const fileSizeFormatted = `${Math.ceil((props.maxFileSize ?? 0) / (1024 * 1024))}MB`;
  return (
    <div className="flex flex-col gap-2" ref={ref}>
      <Wrapper>
        <div className={cn("flex grow items-center justify-between")}>
          <Placeholder>
            <CloudUpload size={16} />
            {disabled
              ? "Maximum files reached"
              : placeholder || "Drag your files here"}
          </Placeholder>

          {disabled ? (
            <button className="uploadcare--widget__button" disabled>
              {buttonText}
            </button>
          ) : (
            <Widget
              // No need to supply value prop to this widget.
              // Ideally we should clear the "value" prop of the widget to reset it's internal state, but we use a random key instead to tell react to re-mount the widget.
              // Three reasons we decided to remove supplying the value prop. All explained in this video: https://cdn.zappy.app/v85e8c58410faeb8318655baacce800fa.mp4
              // 1 - Button UI is different when there is a value + we manage the state outside now anyway
              // 2 - UI to set more files is awkward
              // 3 - Reconciliation does not work for groups + Clearing the filed does not work even if following the documentation
              // Links referenced in the video:
              // https://www.npmjs.com/package/@uploadcare/react-widget
              // https://github.com/uploadcare/react-widget/issues/254#issuecomment-809314181
              // https://github.com/uploadcare/react-widget/issues/254#issuecomment-1541649951
              key={randomWidgetKey}
              ref={widgetRef}
              publicKey={publicKey}
              secureSignature={uploadSignature?.secureSignature}
              secureExpire={
                uploadSignature?.secureExpire
                  ? parseInt(uploadSignature?.secureExpire)
                  : undefined
              }
              metadata={uploadSignature?.metadata}
              onFileSelect={onFileSelect}
              tabs="file camera url facebook gdrive gphotos"
              localeTranslations={{
                buttons: {
                  choose: { files: { one: buttonText, other: buttonText } },
                },
                dialog: {
                  tabs: {
                    file: {
                      button: "Choose local files",
                    },
                  },
                },
                uploading: "Uploading...",
                loadingInfo: "Loading...",
                errors: {
                  // @ts-ignore
                  fileType: "File type not supported",
                  fileMaximumSize: `File exceeds max allowed size (${fileSizeFormatted})`,
                },
                serverErrors: {
                  SignatureExpirationError:
                    "Upload failed. Please refresh your browser and retry.",
                },
              }}
              inputAcceptTypes={acceptTypes}
              validators={[fileExtensionValidator, fileSizeValidator]}
              multipleMax={remainingFileCount}
              multiple={true}
            />
          )}
        </div>
      </Wrapper>
      {helpText ? <HelpText>{helpText}</HelpText> : null}
      <ul className="flex flex-col gap-1">
        {files.map((file, index) => (
          <li key={file.uuid}>
            <FileUploadPreview
              file={file}
              uploadStatus={uploadStatuses?.[index]}
              onRemove={onRemoveFile}
              statusQueryError={isUploadStatusError}
            />
          </li>
        ))}
      </ul>
    </div>
  );
});

FileUploadWidget.displayName = "FileUploadWidget";
