import { Button, ButtonProps } from "block-system/components/Button";
import { Button as ShadcnButton } from "block-system/brickz/components/ui/Button";
import { forwardRef, useState } from "react";
import { trpc } from "utils/trpc";
import { useShadcnComponentsEnabled } from "../brickz/lib/shadcn";
import { cn } from "../brickz/lib/utils";
import { toast } from "@/components/Toaster";

export type InteractButtonProps = {
  tableId: string;
  fieldId: number;
  recordId: string;
  children: React.ReactNode;
  disableOnClick: boolean;

  isEnabled?: boolean;
  isDisabled?: boolean;

  className?: string;
  color?: ButtonProps["color"];

  onClick?: VoidFunction;
  "data-testid"?: string;
};

export const InteractButton = forwardRef<
  HTMLButtonElement,
  InteractButtonProps
>(function InteractButton(
  {
    tableId,
    fieldId,
    recordId,
    disableOnClick,
    isEnabled,
    isDisabled,
    children,
    className,
    color = "secondary",
    onClick,
    "data-testid": dataTestId,
  },
  ref
) {
  const shadCnComponentsEnabled = useShadcnComponentsEnabled();
  const [isDisabledAfterClick, setIsDisabledAfterClick] = useState(false);

  const { isPending, mutate: interactRecordField } =
    trpc.tableFields.interact.useMutation({
      onSuccess() {
        toast.success({ message: "Interaction triggered successfully." });

        setIsDisabledAfterClick(disableOnClick);
      },
      onError(error) {
        console.error(error);
      },
    });

  const disabled =
    isDisabled || isPending || !isEnabled || isDisabledAfterClick;

  const handleOnClick = async (e: React.MouseEvent) => {
    e.stopPropagation();

    const elementLabel = (e.target as HTMLElement)?.innerText.trim();

    interactRecordField({
      tableId,
      recordId: recordId,
      fieldId: fieldId,
      body: {
        interaction: {
          button_click_label: elementLabel,
          custom_label: undefined, // apiValue?.custom_label,
          triggered_by: undefined, // get email from user? 🤔
          is_enabled: undefined, // apiValue?.is_enabled,
          disable_on_click: disableOnClick,
          kind: "button_click",
        },
      },
    });

    onClick?.();
  };

  return (
    <div className="flex h-full w-full items-center justify-center">
      {!shadCnComponentsEnabled ? (
        <Button
          data-testid={dataTestId}
          color={color}
          disabled={isPending || disabled}
          isLoading={isPending}
          onClick={handleOnClick}
          size="small"
          type="button"
          ref={ref}
          className={className}
        >
          {children}
        </Button>
      ) : (
        <ShadcnButton
          data-testid={dataTestId}
          disabled={isPending || disabled}
          isLoading={isPending}
          onClick={handleOnClick}
          type="button"
          variant="outline"
          ref={ref}
          className={cn(className, "w-full border-border")}
        >
          {children}
        </ShadcnButton>
      )}
    </div>
  );
});
