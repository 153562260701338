import "zod-openapi/extend";

// Content blocks
// -----------------------------------------------------------------------------

import { ChatbotBlockSchema } from "block-system/blocks/Chatbot/types";
import { Dispatch, SetStateAction } from "react";
import { z } from "zod";
import { extendZodWithOpenApi } from "zod-openapi";
import {
  BlockIdSchema,
  ComponentBlockStyleSchema,
  ComponentBlockStyle,
} from "./base";
import { MarkdownTextBlockSchema } from "block-system/blocks/MarkdownText/schema";
import { FieldBlockSchema } from "block-system/blocks/Form/Field/schema";
import { FormBlockSchema } from "block-system/blocks/Form/Form/schema";
import { TableBlockSchema } from "block-system/blocks/Table/schema";
import { KanbanBlockSchema } from "block-system/blocks/Kanban/schema";
import { LinksBlockSchema } from "block-system/blocks/Links/Links/schema";
import { LinkCardSchema } from "block-system/blocks/Links/LinksCard/schema";
import { MediaBlockSchema } from "block-system/blocks/Media/schema";
import { DividerBlockSchema } from "block-system/blocks/Divider/schema";
import { EmbedBlockSchema } from "block-system/blocks/Embed/schema";
import { ButtonBlockSchema } from "block-system/blocks/Button/schema";
import { ChecklistBlockSchema } from "block-system/blocks/Checklist/schema";
import { LayoutBlockSchema } from "block-system/blocks/Layout/schema";
import { StripePaymentBlockSchema } from "block-system/blocks/StripePayment/schema";
import { LoginFormBlockSchema } from "block-system/blocks/LoginForm/schema";

extendZodWithOpenApi(z);

export { BlockIdSchema, ComponentBlockStyleSchema };
export type { ComponentBlockStyle };

// Root level components
// -----------------------------------------------------------------------------

const ComponentBlockSchema = z.union([
  ChatbotBlockSchema,
  FormBlockSchema,
  LinksBlockSchema,
  MediaBlockSchema,
  TableBlockSchema,
  LayoutBlockSchema,
]);

export type ComponentBlock = z.infer<typeof ComponentBlockSchema>;

export type ComponentBlockTypes = ComponentBlock["type"];

// All blocks
// -----------------------------------------------------------------------------
export const ContentBlockSchema = z
  .union([
    MarkdownTextBlockSchema,
    FieldBlockSchema,
    FormBlockSchema,
    TableBlockSchema,
    KanbanBlockSchema,
    LinksBlockSchema,
    LinkCardSchema,
    ChatbotBlockSchema,
    MediaBlockSchema,
    DividerBlockSchema,
    EmbedBlockSchema,
    ButtonBlockSchema,
    ChecklistBlockSchema,
    LayoutBlockSchema,
    StripePaymentBlockSchema,
    LoginFormBlockSchema,
  ])
  .openapi({ ref: "InterfaceBlock" });

export type ContentBlock = z.infer<typeof ContentBlockSchema>;

export type ContentBlockTypes = ContentBlock["type"];

export const ContentBlocksSchema = z.array(ContentBlockSchema);
export type ContentBlocks = z.infer<typeof ContentBlocksSchema>;

export const ContentBlockTypeNames: Record<ContentBlockTypes, string> = {
  "divider-block": "Divider",
  "field-block": "Field",
  "form-block": "Form",
  "kanban-block": "Kanban",
  "links-block": "Links",
  "links-card-block": "LinksCard",
  "markdown-text-block": "MarkdownText",
  "media-block": "Media",
  "table-listing-block": "TableListing",
  "embed-block": "Embed",
  "button-block": "Button",
  "checklist-block": "Checklist",
  "chatbot-block": "Chatbot",
  "layout-block": "Layout",
  "stripe-payment-block": "Stripe Payment",
  "login-form-block": "Login Form",
};

/**
 * Since zod either doesn't support extracting all the possible values of `type` from
 * our various blocks, or makes it deceptively difficult, we have to define them
 * explicitly here, and use some craziness below to ensure type safety if there happens
 * to be one missing from this list.
 */
export const BlockTypeSchema = z.enum([
  "field-block",
  "markdown-text-block",
  "form-block",
  "table-listing-block",
  "kanban-block",
  "links-block",
  "links-card-block",
  "chatbot-block",
  "divider-block",
  "media-block",
  "embed-block",
  "button-block",
  "checklist-block",
  "layout-block",
  "stripe-payment-block",
  "login-form-block",
]);

const blockTypes = Object.values(BlockTypeSchema.Values);
type BlockTypes = (typeof blockTypes)[number];

for (const [blockType] of Object.entries(ContentBlockTypeNames)) {
  if (!blockTypes.includes(blockType as ContentBlockTypes))
    throw new Error(`Must add ${blockType} to BlockTypeSchema`);
}

export type ComponentBlockFlag = "Beta" | "Experimental" | "Pro" | "New";

export const ComponentBlockFlags: Partial<
  Record<BlockTypes, ComponentBlockFlag>
> = {
  "chatbot-block": "Beta",
  "embed-block": "Pro",
  "layout-block": "Pro",
  "stripe-payment-block": "New",
};

// Page content
// -----------------------------------------------------------------------------

export const CURRENT_CONTENT_VERSION = "2";
type ContentSchemaType = z.ZodObject<{
  version: z.ZodEnum<[typeof CURRENT_CONTENT_VERSION]>;
  blocks: typeof ContentBlocksSchema;
}>;
export const ContentSchema: ContentSchemaType = z.object({
  version: z.enum([CURRENT_CONTENT_VERSION]),
  blocks: ContentBlocksSchema,
});
export const ContentSchemaWithDefault = ContentSchema.default({
  version: CURRENT_CONTENT_VERSION,
  blocks: [],
}).openapi({ effectType: "input" });
export type Content = z.infer<typeof ContentSchema>;

export type MutateBlocks = Dispatch<SetStateAction<ContentBlocks>>;
