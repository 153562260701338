import { parseHandlebars } from "lib/utils/parseHandlebars";
import { FieldBlock } from "../../Field/types";
import { isAllowedDynamicDefaultValueForField } from "../../Field/utils";

export function parseDynamicDefaultValue<T>(
  block: FieldBlock,
  value: T,
  datasource: Record<string, string>
) {
  if (
    value &&
    typeof value === "string" &&
    isAllowedDynamicDefaultValueForField(
      block.config.inputType,
      value.replace(/ /g, "")
    )
  ) {
    return parseHandlebars(value.replace(/ /g, ""), datasource);
  }
  return value;
}

// TODO: INTRFCS-3865
export function getDefaultValueForFieldBlock(fieldBlock: FieldBlock) {
  const type = fieldBlock.config.inputType;
  if (fieldBlock.config.defaultValue) {
    return fieldBlock.config.defaultValue;
  }
  switch (type) {
    case "text":
    case "textarea":
    case "phone-number":
    case "currency":
    case "email":
    case "url":
    case "multi-url":
    case "number":
    case "file-upload":
      return "";
    case "multiple-file-upload":
      return [];
    case "date-picker":
      if (fieldBlock.config.defaultToNow) {
        return new Date();
      }

      return "";
    case "checkbox":
      if (fieldBlock.config.defaultChecked) {
        return true;
      }

      return false;
    case "yes-no":
    case "ai-formula":
    case "linked-record":
    case "multiple-linked-record":
      return undefined;
    case "dropdown":
      if (fieldBlock.config.multiSelect) {
        return [];
      }

      return "";
    default:
      const _exhaustiveCheck: never = type;
      return _exhaustiveCheck;
  }
}
