import { forwardRef } from "react";
import { Option } from "./types";
import { useChoices } from "./useChoices";
import {
  DropdownSelect,
  DropdownSelectValue,
} from "@/block-system/brickz/components/ui/DropdownSelect";
import { Spinner } from "block-system/components/Spinner";

type DynamicDropdownSelectValue =
  /**
   * For dynamic dropdowns without "Default value" in the block config.
   */
  | Option
  | Option[]
  /**
   * When user provides a "Default value" in the block config.
   */
  | string
  /**
   * When user provides a "Default value", AND the dropdown is multi-select.
   */
  | string[]
  /**
   * When user clears a single-select non-required dropdown.
   */
  | null;

type Props = {
  tableFieldId: number;
  tableId: string;
  tablesFieldKey: string | undefined;
  onChange: (newOptions: Option | Option[] | null) => void;
  multiSelect?: boolean;
  value: DynamicDropdownSelectValue;
  placeholder?: string;
  isErrored: boolean;
  isRequired: boolean;
  className?: string;
  id: string;
  isDisabled: boolean;
  menuContainerClassName?: string;
  menuAriaLabel?: string;
};

export const DynamicDropdownSelect = forwardRef<HTMLButtonElement, Props>(
  (
    {
      tableFieldId,
      tableId,
      tablesFieldKey,
      onChange,
      multiSelect,
      value,
      placeholder,
      isErrored,
      isRequired,
      className,
      id,
      isDisabled,
      menuContainerClassName,
      menuAriaLabel,
    },
    ref
  ) => {
    const { data: tableFieldOptions, isLoading } = useChoices({
      tableFieldId,
      tableId,
      tablesFieldKey,
    });

    const tableFieldOptionFromValue = (value: string): Option | null => {
      const foundOption = tableFieldOptions.find((option) => {
        return option.value === value;
      });

      return foundOption ?? null;
    };

    const handleOnChange = (newValue: DropdownSelectValue) => {
      if (!newValue) {
        return onChange?.(null);
      }

      if (typeof newValue === "string") {
        const option = tableFieldOptionFromValue(newValue);
        return onChange?.(option);
      }

      const options = newValue.reduce<Option[]>((mappedOptions, value) => {
        const mappedOption = tableFieldOptionFromValue(value);
        if (mappedOption) {
          mappedOptions.push(mappedOption);
        }

        return mappedOptions;
      }, []);

      return onChange?.(options);
    };

    if (isLoading) {
      return <Spinner containerClassName={"my-0"} height={50} />;
    }

    const dropdownValue = getDropdownSelectValue({ value, tableFieldOptions });
    return (
      <DropdownSelect
        ref={ref}
        isErrored={isErrored}
        isDisabled={isDisabled}
        isRequired={isRequired}
        options={tableFieldOptions}
        className={className}
        menuContainerClassName={menuContainerClassName}
        multiSelect={multiSelect}
        value={dropdownValue}
        placeholder={placeholder}
        onChange={handleOnChange}
        id={id}
        menuAriaLabel={menuAriaLabel}
      />
    );
  }
);

DynamicDropdownSelect.displayName = "DynamicDropdownSelect";

const getDropdownSelectValue = ({
  value,
  tableFieldOptions,
}: {
  value: DynamicDropdownSelectValue;
  tableFieldOptions: Option[];
}) => {
  if (!value) {
    return null;
  }

  if (typeof value === "string") {
    const foundOption = tableFieldOptions.find((option) => {
      // TODO: INTRFCS-3865. Consider checking for `.label` as well!
      return option.value === value;
    });

    if (!foundOption) {
      return null;
    }

    return foundOption.value;
  }

  if (typeof value === "object" && "value" in value) {
    return value.value;
  }

  if (!Array.isArray(value)) {
    return null;
  }

  return value.reduce<string[]>((valuesPresentInFieldOptions, valueToCheck) => {
    const foundOption = tableFieldOptions.find((option) => {
      if (typeof valueToCheck === "string") {
        // TODO: INTRFCS-3865. Consider checking for `.label` as well!
        return option.value === valueToCheck;
      }

      // TODO: INTRFCS-3865. Consider checking for `.label` as well!
      return option.value === valueToCheck.value;
    });

    if (foundOption) {
      valuesPresentInFieldOptions.push(foundOption.value);
    }

    return valuesPresentInFieldOptions;
  }, []);
};
