import { forwardRef, useState } from "react";

import { cn } from "@/block-system/brickz/lib/utils";
import { truncate } from "lodash";
import { isValidLinkedRecordFormat } from "@/block-system/blocks/Table/Block_OLD/utils";

type LinkedRecordsInputProps = React.ComponentPropsWithoutRef<"div"> & {
  value:
    | { source: string; label: string }
    | { source: string; label: string }[]
    | undefined;
};

const LinkedRecordsInput = forwardRef<HTMLDivElement, LinkedRecordsInputProps>(
  ({ value, ...props }, ref) => {
    const MAXDISPLAYCOUNT = 5;
    const records = Array.isArray(value) ? value : [value];
    const [displayAll, setDisplayAll] = useState(false);
    const recordsToDisplay = displayAll
      ? records
      : records.slice(0, MAXDISPLAYCOUNT);

    const handleSeeMoreClick = () => {
      setDisplayAll(!displayAll);
    };

    if (!isValidLinkedRecordFormat(records)) {
      return (
        <div
          ref={ref}
          className={cn(
            "min-h-28 cursor-not-allowed rounded-md border border-input px-3 py-2.5"
          )}
        />
      );
    }

    return (
      <div
        className={cn(
          "min-h-28 cursor-not-allowed rounded-md border border-input px-3 py-2.5"
        )}
        ref={ref}
        {...props}
      >
        <ul className={cn("flex flex-wrap items-start gap-2")}>
          {recordsToDisplay.map((record, index) => (
            <li
              key={index}
              className="rounded-md border border-input px-1.5 py-1 text-sm"
              style={{ width: "fit-content" }}
            >
              {truncate(record?.label, { length: 100 })}
            </li>
          ))}
        </ul>
        {records.length > MAXDISPLAYCOUNT ? (
          <button
            aria-label={displayAll ? "See less" : "See more"}
            type="button"
            className="mt-2 cursor-pointer text-sm underline"
            onClick={handleSeeMoreClick}
          >
            {displayAll ? "See less" : "See more"}
          </button>
        ) : null}
      </div>
    );
  }
);

LinkedRecordsInput.displayName = "LinkedRecordsInput";

export { LinkedRecordsInput };
