import { MAX_WIDTH_NAME_TO_CLASSNAMES } from "block-system/components/BlockWrapper/constants";
import type { DividerBlock } from "../types";
import { cn } from "utils/cn";

const spacingOptions = {
  small: "py-2",
  medium: "py-4",
  large: "py-8",
} as const;

export const Divider = ({
  spacing = "medium",
  width = "medium",
}: DividerBlock["config"]) => {
  return (
    <hr
      className={cn(
        "border-none",
        "after:absolute after:top-1/2 after:h-[1px] after:w-full after:rounded-small after:bg-border after:content-['']",
        spacingOptions[spacing],
        MAX_WIDTH_NAME_TO_CLASSNAMES[width]
      )}
    />
  );
};
