import { cn } from "@/block-system/brickz/lib/utils";
import { Text } from "@zapier/design-system";
import { IconButton } from "@zapier/design-system-beta";

export function LayoutItemZeroState() {
  return (
    <div
      className={cn(
        "flex flex-col items-center justify-center gap-4",
        "h-[300px] w-full",
        "border border-border bg-card",
        "rounded"
      )}
    >
      <IconButton name="formAdd" variant="secondary" size="medium" />
      <Text type="SmallPrint2">Add component</Text>
    </div>
  );
}
