import { AIFormula } from "@/block-system/brickz/components/ui/AIFormula";
import { CheckboxInput } from "@/block-system/brickz/components/ui/CheckboxInput";
import { CurrencyInput } from "@/block-system/brickz/components/ui/CurrencyInput";
import { DatePicker } from "@/block-system/brickz/components/ui/DatePicker";
import { DropdownSelect } from "@/block-system/brickz/components/ui/DropdownSelect";
import {
  ConditionallyRenderedFieldLabel,
  Field,
  ReadOnlyFieldLabel,
  type FieldProps,
} from "@/block-system/brickz/components/ui/Field";
import { LinkedRecordsInput } from "@/block-system/brickz/components/ui/LinkedRecordsInput";
import { PhoneNumberInput } from "@/block-system/brickz/components/ui/PhoneNumberInput";
import { Textarea } from "@/block-system/brickz/components/ui/Textarea";
import { TextInput } from "@/block-system/brickz/components/ui/TextInput";
import { UrlInput } from "@/block-system/brickz/components/ui/UrlInput";
import { FileUpload } from "@/block-system/components/forms/FileUpload";
import { YesNoInput } from "@/block-system/components/forms/YesNoInput";
import { useCurrentConsumerProject } from "@/lib/context/current-consumer-project-context";
import { useIsNewMultiUploaderEnabled } from "@/lib/context/split-context";
import { BlockWrapper, DynamicDropdownSelect } from "block-system/components";
import { Spinner } from "block-system/components/Spinner";
import { BlockId, ContentBlock } from "block-system/types";
import { usePageContext } from "lib/context/page-context";
import { useContentDatasource } from "lib/hooks/useContentDatasource";
import { useFeatureAccessCheck } from "lib/hooks/useFeatureAccessCheck";
import { parseHandlebars } from "lib/utils/parseHandlebars";
import type { CountryCode } from "libphonenumber-js/types";
import { trim } from "lodash";
import { Suspense, useMemo } from "react";
import {
  Controller,
  useFormContext,
  type ControllerRenderProps,
  type FieldValues,
} from "react-hook-form";
import ReactMarkdown from "react-markdown";
import { isConditionallyRendered } from "../lib/conditional-logic/conditional-logic";
import { DateFormatOptions } from "../schema";
import type {
  FieldBlock as FieldBlockType,
  SupportedCurrencies,
} from "../types";
import { isDynamicVariable } from "../utils";
import { buildValidationRules, calculateAllowedFileCount } from "./utils";

type RenderInputProps = Parameters<FieldProps["renderInput"]>[0];

const MEGABYTE = 1024 * 1024;

export const FREE_FILE_SIZE = 5 * MEGABYTE;
export const PREMIUM_FILE_SIZE = 10 * MEGABYTE;
export const ADVANCED_FILE_SIZE = 25 * MEGABYTE;

// The max file counts are arbitrary. Given to us by product team.
// Engineering team suggested a max file count of 100 instead of unlimited to reduce scope and engineering complexity.
export const FREE_USER_MAX_ALLOWED_FILE_COUNT = 3;
export const PAID_USERS_MAX_ALLOWED_FILE_COUNT = 100;

export function FieldBlock(props: {
  block: FieldBlockType;
  blocks: ContentBlock[];
  blockId: BlockId;
}) {
  const { config } = props.block;
  const { data: datasource } = useContentDatasource();
  const { creator, paidFeatureAccess } = useCurrentConsumerProject();

  let maxFileSize = FREE_FILE_SIZE;
  let maxFileCount = FREE_USER_MAX_ALLOWED_FILE_COUNT;

  // ToDo: Maybe useFeatureAccessCheck could take a list of features to check for?
  const allowAdvancedFileSize = useFeatureAccessCheck("fileSizeLimit25mb", {
    ...creator,
    paidFeatureAccess,
  });
  const allowPremiumFileSize = useFeatureAccessCheck("fileSizeLimit10mb", {
    ...creator,
    paidFeatureAccess,
  });

  const allowPaidFileUploadCount = useFeatureAccessCheck(
    "extendedFileUploads",
    {
      ...creator,
      paidFeatureAccess,
    }
  );

  if (allowAdvancedFileSize) {
    maxFileSize = ADVANCED_FILE_SIZE;
  } else if (allowPremiumFileSize) {
    maxFileSize = PREMIUM_FILE_SIZE;
  } else {
    maxFileSize = FREE_FILE_SIZE;
  }

  if (allowPaidFileUploadCount) {
    maxFileCount = PAID_USERS_MAX_ALLOWED_FILE_COUNT;
  }

  const { isEditing } = usePageContext();

  const name = config.name;

  const validationRules = buildValidationRules(config);

  const {
    formState: { errors, isSubmitting },
  } = useFormContext();

  const isNewMultiUploaderEnabled = useIsNewMultiUploaderEnabled();
  const hideLabel = !config.label || config.inputType === "checkbox";

  if (config.hidden) {
    return null;
  }

  const customLabelRenderer = renderCustomLabel({
    block: props.block,
    isEditing,
  });

  return (
    <BlockWrapper blockId={props.blockId} block={props.block}>
      <Suspense fallback={<Spinner height={20} />}>
        <Controller
          key={config.id}
          name={name}
          rules={validationRules}
          render={({ field: controllerProps }) => (
            <Field
              inputId={config.name}
              label={config.label}
              renderLabel={customLabelRenderer}
              isDisabled={isSubmitting}
              isRequired={config.required}
              renderHelpText={() => {
                return (
                  <FieldHelpText
                    helpText={config.helpText}
                    isEditing={isEditing}
                    datasource={datasource}
                    isNewMultiUploaderEnabled={isNewMultiUploaderEnabled}
                    inputType={config.inputType}
                  />
                );
              }}
              error={errors?.[name]?.message as string | undefined}
              renderInput={(inputProps) => {
                return (
                  <FieldInput
                    config={config}
                    inputProps={inputProps}
                    controllerProps={controllerProps}
                    isEditing={isEditing}
                    datasource={datasource}
                    maxFileSize={maxFileSize}
                    maxFileCount={maxFileCount}
                  />
                );
              }}
              hideLabel={hideLabel}
            />
          )}
        />
      </Suspense>
    </BlockWrapper>
  );
}

function FieldHelpText({
  helpText,
  isEditing,
  datasource,
  isNewMultiUploaderEnabled,
  inputType,
}: {
  helpText: string | undefined;
  isEditing: boolean;
  datasource: ReturnType<typeof useContentDatasource>["data"];
  isNewMultiUploaderEnabled?: boolean;
  inputType?: FieldBlockType["config"]["inputType"];
}) {
  const content = useMemo(() => {
    if (isEditing) {
      return helpText ?? "";
    }

    return parseHandlebars(helpText ?? "", datasource);
  }, [helpText, isEditing, datasource]);

  if (!content) {
    return null;
  }

  if (
    // For forms, the render input will handle the help text
    isNewMultiUploaderEnabled &&
    (inputType === "file-upload" || inputType === "multiple-file-upload")
  ) {
    return null;
  }

  return (
    <ReactMarkdown
      allowedElements={[
        "a",
        "p",
        "strong",
        "em",
        "code",
        "pre",
        "li",
        "ol",
        "ul",
      ]}
      linkTarget="_blank"
    >
      {content}
    </ReactMarkdown>
  );
}

function FieldInput({
  config,
  inputProps,
  controllerProps,
  isEditing,
  datasource,
  maxFileSize,
  maxFileCount,
}: {
  config: FieldBlockType["config"];
  inputProps: RenderInputProps;
  controllerProps: ControllerRenderProps<FieldValues, string>;
  isEditing: boolean;
  datasource: ReturnType<typeof useContentDatasource>["data"];
  maxFileSize: number;
  maxFileCount: number;
}) {
  const isNewMultiUploaderEnabled = useIsNewMultiUploaderEnabled();

  if (
    !isEditing &&
    config.placeholder &&
    isDynamicVariable(config.placeholder.replace(/\s/g, ""))
  ) {
    config.placeholder = parseHandlebars(config.placeholder, datasource);
  }

  let allowedFileSize = FREE_FILE_SIZE;
  let helpText: React.ReactNode | null = null;

  if (
    config.inputType === "file-upload" ||
    config.inputType === "multiple-file-upload"
  ) {
    // This ensures that we never give away more upload size than allowed by the plan
    if (config.allowedFileSize) {
      allowedFileSize = Math.min(config.allowedFileSize, maxFileSize);
    }

    helpText = (
      <FieldHelpText
        helpText={config.helpText}
        isEditing={isEditing}
        datasource={datasource}
      />
    );
  }

  switch (config.inputType) {
    case "text":
    case "number":
      return (
        <TextInput
          {...inputProps}
          {...controllerProps}
          placeholder={config.placeholder}
          type="text"
        />
      );
    case "email":
      return (
        <TextInput
          {...inputProps}
          {...controllerProps}
          placeholder={config.placeholder}
          type={config.inputType}
        />
      );
    case "url":
      return (
        <UrlInput
          {...inputProps}
          {...controllerProps}
          placeholder={config.placeholder}
        />
      );

    case "multi-url":
      return (
        <UrlInput
          {...inputProps}
          {...controllerProps}
          placeholder={config.placeholder}
        />
      );

    case "textarea":
      return (
        <Textarea
          {...inputProps}
          {...controllerProps}
          rows={3}
          placeholder={config.placeholder}
          name={config.name}
        />
      );

    case "phone-number":
      return (
        <PhoneNumberInput
          {...inputProps}
          {...controllerProps}
          placeholder={config.placeholder}
          defaultCountry={config.defaultCountry as CountryCode}
        />
      );

    case "dropdown":
      // make separate DynamicDropdown component that calls Tables API and conditionally render it if this won't be a static dropdown
      // otherwise use static one as below
      if (config.tableId && config.tableFieldId) {
        return (
          <DynamicDropdownSelect
            {...inputProps}
            {...controllerProps}
            menuAriaLabel={`${config.label} options`}
            placeholder={config.placeholder}
            tableId={config.tableId}
            tableFieldId={Number(config.tableFieldId)}
            tablesFieldKey={config.tablesFieldKey}
            multiSelect={config.multiSelect}
          />
        );
      }
      return (
        <DropdownSelect
          {...inputProps}
          {...controllerProps}
          menuAriaLabel={`${config.label} options`}
          placeholder={config.placeholder}
          options={getDropdownOptions(config.options)}
          multiSelect={config.multiSelect}
        />
      );

    case "yes-no":
      return <YesNoInput {...inputProps} {...controllerProps} />;

    case "date-picker":
      return (
        <DatePicker
          {...inputProps}
          {...controllerProps}
          name={config.name}
          dateFormat={config.dateFormat ?? DateFormatOptions[0].value}
          placeholder={config.placeholder}
          includeTime={config.includeTime}
          defaultToNow={config.defaultToNow}
        />
      );

    case "checkbox":
      return (
        <CheckboxInput
          {...inputProps}
          {...controllerProps}
          label={config.label}
          name={config.name}
          required={config.required}
          defaultChecked={config.defaultChecked}
          text={config.text}
        />
      );

    case "currency": {
      return (
        <CurrencyInput
          {...inputProps}
          {...controllerProps}
          placeholder={config.placeholder}
          currencyFormat={config.currencyFormat as SupportedCurrencies}
        />
      );
    }

    case "file-upload":
      return (
        <FileUpload
          {...inputProps}
          {...controllerProps}
          name={config.name}
          required={config.required}
          placeholder={config.placeholder}
          allowedFileTypes={config.allowedFileTypes}
          maxFileSize={allowedFileSize}
          blockId={config.id ?? ""}
          isEditing={isEditing}
          helpText={helpText}
        />
      );

    case "multiple-file-upload":
      // if interface owners have already created a multiple file upload field, and we turn off the feature flag, we will
      // fallback to a url input field. Users can enter comma separated urls instead. But the config still stays as
      // multiple-file-upload.
      if (!isNewMultiUploaderEnabled) {
        return (
          <UrlInput
            {...inputProps}
            {...controllerProps}
            placeholder={config.placeholder}
          />
        );
      }

      const allowedFileCount = calculateAllowedFileCount({
        maxFileCount: maxFileCount,
        configuredFileCount: config.allowedFileCount,
      });

      return (
        <FileUpload
          {...inputProps}
          {...controllerProps}
          name={config.name}
          required={config.required}
          placeholder={config.placeholder}
          allowedFileTypes={config.allowedFileTypes}
          maxFileSize={allowedFileSize}
          blockId={config.id ?? ""}
          isEditing={isEditing}
          helpText={helpText}
          multiUploadConfig={{
            allowedFileCount,
          }}
        />
      );

    case "ai-formula":
      return <AIFormula value={controllerProps.value?.value || ""} />;

    case "multiple-linked-record":
      return <LinkedRecordsInput value={controllerProps.value} />;

    case "linked-record":
      return <LinkedRecordsInput value={controllerProps.value} />;

    default:
      const _exhaustiveCheck: never = config;
      return _exhaustiveCheck;
  }
}

export function getDropdownOptions(options?: string) {
  return (
    options
      ?.split("\n")
      .map(trim)
      .filter((option) => !!option)
      .map((option) => ({
        label: option,
        value: option,
      })) ?? []
  );
}

const renderCustomLabel = ({
  isEditing,
  block,
}: {
  isEditing: boolean;
  block: FieldBlockType;
}): FieldProps["renderLabel"] => {
  if (isEditing && isConditionallyRendered(block)) {
    return function Component(props) {
      return (
        <ConditionallyRenderedFieldLabel
          {...props}
          label={block.config.label}
        />
      );
    };
  }

  if (block.config.inputType === "ai-formula") {
    return function Component(props) {
      return <ReadOnlyFieldLabel {...props} label={block.config.label} />;
    };
  }

  if (block.config.inputType === "multiple-linked-record") {
    return function Component(props) {
      return <ReadOnlyFieldLabel {...props} label={block.config.label} />;
    };
  }

  if (block.config.inputType === "linked-record") {
    return function Component(props) {
      return <ReadOnlyFieldLabel {...props} label={block.config.label} />;
    };
  }

  return undefined;
};
