import { useTheme } from "lib/theme";
import { ImageBlock } from "../components/ImageBlock";
import { LogoBlock } from "../components/LogoBlock";
import { VideoBlock } from "../components/VideoBlock";
import type { MediaBlock } from "../types";
import { cn } from "utils/cn";
import { ALIGNMENT_TO_CLASSNAMES } from "block-system/components/BlockWrapper/constants";
import { useCurrentConsumerProject } from "@/lib/context/current-consumer-project-context";
import { isValidVideoProviderUrl } from "../utils";
import { MediaZeroState } from "../components/MediaZeroState";

const contentAlignmentOptions = {
  center: "justify-center",
  left: "justify-start",
  right: "justify-end",
} as const;

type MediaProps = MediaBlock["config"] & {
  isEditing?: boolean;
};

export function Media(props: MediaProps) {
  const project = useCurrentConsumerProject();
  const theme = useTheme();
  const height = props.height;
  const maxWidth = props.style?.width
    ? theme.app.width[props.style?.width]
    : theme.app.width.narrow;

  const renderMediaBlock = (config: MediaBlock["config"]) => {
    switch (config.mediaType) {
      case "image":
        return <ImageBlock {...config} />;
      case "video":
        return <VideoBlock {...config} />;
      case "logo":
        return <LogoBlock alt={config.alt} />;
      default:
        const _exhaustiveCheck: never = config.mediaType;
        return _exhaustiveCheck;
    }
  };

  const isValidSource = validateMediaSource({
    config: props,
    logoUrl: project?.logo?.url,
  });

  return (
    <div
      style={{ maxWidth, height }}
      className={cn(
        "flex w-full overflow-hidden",
        props.isEditing
          ? null
          : ALIGNMENT_TO_CLASSNAMES[props.style?.alignment ?? "center"],
        contentAlignmentOptions[props.alignContent]
      )}
    >
      {isValidSource ? (
        renderMediaBlock(props)
      ) : props.isEditing ? (
        <MediaZeroState mediaType={props.mediaType} />
      ) : null}
    </div>
  );
}

function validateMediaSource({
  config,
  logoUrl,
}: {
  config: MediaBlock["config"];
  logoUrl?: string;
}) {
  if (config.mediaType === "image" && config.mediaUrl) {
    return true;
  }

  if (
    config.mediaType === "video" &&
    config.mediaUrl &&
    isValidVideoProviderUrl(config.mediaUrl)
  ) {
    return true;
  }

  if (config.mediaType === "logo" && logoUrl) {
    return true;
  }

  return false;
}
