import "zod-openapi/extend";

import { z } from "zod";
import type { Block } from "../__shared__/types";
import { components } from "@zapier/api-clients/apis/chatbots/types";

export type ChatbotConfig = components["schemas"]["Chatbot"]["config"];
export type NewEvent = components["schemas"]["NewEvent"];
export type Action = components["schemas"]["Action"];
export type ButtonAction = components["schemas"]["ButtonAction"];
export type SuggestionsAction = components["schemas"]["SuggestionsAction"];
export type SuggestionFields = NonNullable<
  SuggestionsAction["config"]["suggestions"]
>;
export type SuggestionField = SuggestionFields[0];
export type KnowledgeSource = components["schemas"]["KnowledgeSource"];

export const ChatbotStyleSchema = z.object({
  width: z
    .union([
      z.literal("full"),
      z.literal("wide"),
      z.literal("medium"),
      z.literal("narrow"),
    ])
    .optional(),
  alignment: z
    .union([z.literal("left"), z.literal("center"), z.literal("right")])
    .optional(),
  height: z.union([z.literal("default"), z.literal("custom")]).optional(),
  heightValue: z.number().optional(),
});

export type ChatbotStyle = z.infer<typeof ChatbotStyleSchema>;

export const TEMPLATE_PARAM_REGEX = /\$\{\{ *([A-Za-z0-9_.]+) *\}\}/g;
export const ChatbotBlockConfigSchema = z.object({
  id: z
    .string()
    .cuid()
    .or(z.string().regex(/^_BLOCK_(\d+)$/))
    .optional(),
  chatbotId: z
    .string()
    .cuid()
    // TODO: get this out of the main schemas and into zsl-api
    .or(z.string().regex(TEMPLATE_PARAM_REGEX))
    .optional(),
  isConnectedChatbot: z
    .boolean()
    .optional()
    .default(false)
    .openapi({ effectType: "input" }),
  style: ChatbotStyleSchema.default({
    width: "medium",
    alignment: "center",
    height: "default",
  }).openapi({ effectType: "input" }),
});

export type ChatbotBlockConfig = z.infer<typeof ChatbotBlockConfigSchema>;

export const ChatbotBlockSchema = z.object({
  type: z.literal("chatbot-block"),
  config: ChatbotBlockConfigSchema,
  errors: z
    .array(
      z.object({
        code: z.enum([
          "chatbot:authentication-does-not-exist",
          "chatbot:authentication-test-failed",
          "chatbot:model-not-available",
          "chatbot:max-tokens-too-high",
          "chatbot:chatbot-does-not-exist",
        ]),
        blockId: z.string().nullable(),
        path: z.array(z.union([z.string(), z.number()])),
      })
    )
    .optional(),
});

export type ChatbotBlock = z.infer<typeof ChatbotBlockSchema>;

export interface Chatbot extends Block<ChatbotBlock> {
  strings: {
    readonly NAME: string;
    readonly DESCRIPTION: string;
  };
  blockType: "chatbot-block";
  blockTypeDbName: "Chatbot";
  blockTypeToName: {
    readonly "chatbot-block": "Chatbot";
  };
  blockNameToType: {
    readonly Chatbot: "chatbot-block";
  };
}
