import { Button } from "@zapier/design-system";
import {
  ResponsiveNav,
  ResponsiveNavProps,
} from "block-system/brickz/components/ui/ResponsiveNav";
import { ContentBlocks } from "block-system/types";
import { defaultOpensInNewtab } from "block-system/utilities/navigation-helpers";
import { BlockDropzone } from "components/Blocks/BlockDropzone";
import { LoggedInConsumerLabel } from "components/LoggedInConsumerLabel";
import { useEmbed } from "lib/context/embed-context";
import { PageContextValue, usePageContext } from "lib/context/page-context";
import { DragTypes } from "lib/react-dnd";
import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import dynamic from "next/dynamic";
import { ComponentProps, useMemo } from "react";
import { useDragLayer } from "react-dnd";
import { cn } from "utils/cn";
import { trpc } from "utils/trpc";
import { withHttps } from "utils/withHttps";
import Blocks from "../Blocks";
import { useCurrentConsumerProject } from "lib/context/current-consumer-project-context";
import { NAVIGATION_MENU_Z_INDEX } from "@/block-system/brickz/components/ui/navigation-menu";

const AddBlockButton = dynamic(() => import("../AddBlockButton"), {
  ssr: false,
  loading() {
    return <div style={{ height: 40 }} />; // height of the add component button
  },
});

export const PageWrapper: React.FunctionComponent<
  React.ComponentPropsWithoutRef<"div"> & {
    $isEditing: boolean;
  }
> = ({ $isEditing, ...props }) => {
  const interfacesTheme = useInterfacesTheme();
  const { noBackground } = useEmbed();
  return (
    <main
      {...props}
      className={cn(
        {
          "min-h-full p-[50px_15px]": $isEditing,
          "min-h-screen p-[50px_15px_80px]": !$isEditing,
          "text-zi-text": !interfacesTheme,
          "text-foreground": interfacesTheme,
          "bg-zi-pageBackground": !interfacesTheme && !noBackground,
          "bg-background": interfacesTheme && !noBackground,
          "bg-transparent": noBackground,
        },
        props.className
      )}
    />
  );
};

type ConsumerInfo =
  | { name: string | null; email: string; avatar: string; id: string }
  | undefined;

export function Page(props: {
  blocks: ContentBlocks;
  consumerInfo?: ConsumerInfo;
}) {
  const {
    allPages,
    globalNavigation,
    includeLogo,
    isEditing,
    isThumbnail,
    navigationLinks,
    navigationEnabled,
    pageId,
    projectName,
    projectPublicName,
    logo,
    setFocusState,
    addBlock,
  } = usePageContext();

  const { isHidden } = useCurrentConsumerProject();

  const { logoHref, navItems } = useMemo(() => {
    const hasAllPages = typeof allPages === "object" && allPages !== null;
    const canBuildLinkedPages = Array.isArray(navigationLinks) && hasAllPages;

    const homepage = hasAllPages
      ? Object.values(allPages).find((p) => p.isHomepage)
      : undefined;

    const homepageLogoHref = homepage ? homepage.url : "/";

    return canBuildLinkedPages
      ? {
          logoHref: homepageLogoHref,
          navItems: navigationLinks.map((navLink) => {
            const opensInNewTab =
              navLink.opensInNewTab ?? defaultOpensInNewtab(navLink.type);
            if (navLink.type === "page") {
              const page = allPages[navLink.pageId];
              return {
                current: page.id === pageId,
                href: page.url,
                name: page.title,
                target: opensInNewTab
                  ? ("_blank" as const)
                  : ("_self" as const),
              };
            }
            return {
              current: false,
              href: withHttps(navLink.url),
              name: navLink.title,
              target: opensInNewTab ? ("_blank" as const) : ("_self" as const),
            };
          }),
        }
      : {
          logoHref: homepageLogoHref,
          navItems: [],
        };
  }, [allPages, navigationLinks, pageId]);

  return (
    <>
      <EditablePageNav
        consumerInfo={props.consumerInfo}
        globalNavigation={globalNavigation}
        includeLogo={includeLogo}
        isEditing={isEditing}
        isThumbnail={isThumbnail}
        logo={logo}
        logoHref={logoHref}
        navItems={navItems}
        navigationEnabled={navigationEnabled}
        projectName={projectName}
        projectPublicName={projectPublicName}
        setFocusState={setFocusState}
      />
      <PageWrapper
        $isEditing={isEditing}
        className={cn({
          "flex !min-h-full flex-1 flex-col !p-0": isHidden,
        })}
      >
        <BlockDropzone
          className={cn("flex flex-col flex-nowrap items-center gap-y-10", {
            "min-h-screen": isEditing,
            "flex flex-1 flex-col": isHidden,
          })}
          disableDrop={!isEditing}
        >
          <Blocks blocks={props.blocks} />
          <AddBlockButtonDisplay isEditing={isEditing} addBlock={addBlock} />
        </BlockDropzone>

        {props.consumerInfo && !isEditing ? (
          <LoggedInConsumerLabel consumer={props.consumerInfo} />
        ) : null}
      </PageWrapper>
    </>
  );
}

function EditablePageNav({
  isEditing,
  isThumbnail,
  consumerInfo,
  projectName,
  projectPublicName,
  logo,
  includeLogo,
  navItems,
  logoHref,
  setFocusState,
  globalNavigation,
  navigationEnabled,
}: Pick<
  PageContextValue,
  | "isEditing"
  | "isThumbnail"
  | "projectName"
  | "projectPublicName"
  | "includeLogo"
  | "globalNavigation"
  | "navigationEnabled"
  | "setFocusState"
> & {
  consumerInfo: ConsumerInfo;
  logo: ResponsiveNavProps["logo"];
  navItems: ResponsiveNavProps["navItems"];
  logoHref: string;
}) {
  if (!globalNavigation || !navigationEnabled) {
    return null;
  }

  return (
    <div className="sticky top-0 z-[1]">
      <div
        data-editing={isEditing}
        className={cn(
          "group relative",
          "[&[data-editing='true']:hover>:first-child]:blur-[2px] [&[data-editing='true']>:first-child]:blur-0",
          "[&[data-editing='true']>:first-child]:transition-all [&[data-editing='true']>:first-child]:duration-200"
        )}
      >
        {!isThumbnail && (
          <ResponsivePageNav
            consumerInfo={consumerInfo}
            navItems={navItems}
            logoHref={logoHref}
            includeLogo={includeLogo === true}
            projectName={projectPublicName || projectName}
            logo={logo}
          />
        )}
        {isEditing ? (
          <div
            className={cn(
              "absolute inset-0 flex items-center justify-center",
              NAVIGATION_MENU_Z_INDEX
            )}
          >
            <div
              className={cn(
                "pointer-events-none -translate-y-5 opacity-0",
                "transition-all duration-150",
                "group-hover:pointer-events-auto group-hover:translate-y-0 group-hover:opacity-100"
              )}
            >
              <Button
                color="primary"
                iconBefore="actionEdit"
                onClick={() =>
                  setFocusState?.({ sideNavDrawer: "settings-navigation" })
                }
                size="compact"
                type="button"
              >
                Edit in settings
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

function ResponsivePageNav({
  consumerInfo,
  navItems,
  logoHref,
  includeLogo,
  projectName,
  logo,
}: {
  consumerInfo: ConsumerInfo | undefined;
  navItems: ComponentProps<typeof ResponsiveNav>["navItems"];
  logoHref: string;
  includeLogo: boolean;
  projectName: string | undefined;
  logo: ComponentProps<typeof ResponsiveNav>["logo"];
}) {
  const utils = trpc.useUtils();

  const profileItems = useMemo(() => {
    if (!consumerInfo) return [];

    return [
      {
        name: "Log out",
        onClick: async () => {
          try {
            await utils.projectAuth.logout.fetch();
          } catch (error) {
            console.error("Failed to logout:", error);
          } finally {
            const { pathname } = new URL(window.location.href);
            const isOnRootPath = pathname === "/";
            /**
             * Historically, we used the `router.replace("/")` here.
             * That did not work, since Next.js will NOT re-mount the component
             * if we navigate to the same page we are currently on.
             *
             * https://nextjs.org/docs/pages/api-reference/functions/use-router#resetting-state-after-navigation
             *
             * We want to force the browser to reload the page to ensure all the state is reset.
             */
            if (isOnRootPath) {
              /**
               * We are not using `window.location.href = "/"` because we observed the page sometimes wont reload if
               * the user is already on the "/" path.
               */
              window.location.reload();
            } else {
              window.location.href = "/";
            }
          }
        },
      },
    ];
  }, [consumerInfo, utils.projectAuth.logout]);

  return (
    <ResponsiveNav
      interfaceName={projectName}
      navItems={navItems}
      profileMenuItems={profileItems}
      user={{
        name: consumerInfo?.name,
        email: consumerInfo?.email,
        avatarUrl: consumerInfo?.avatar,
        avatarAltText: consumerInfo?.name,
      }}
      includeLogo={includeLogo}
      logoHref={logoHref}
      logoAltText={projectName}
      logo={logo}
    />
  );
}

const DRAG_TYPES: Array<string | symbol> = [
  DragTypes.CONTENT_BLOCK,
  DragTypes.NEW_CONTENT_BLOCK_TYPE,
];

function AddBlockButtonDisplay({
  isEditing,
  addBlock,
}: {
  isEditing: boolean;
  addBlock?: VoidFunction;
}) {
  const isDragging = useDragLayer((monitor) => {
    const type = monitor.getItemType();
    return type !== null && DRAG_TYPES.includes(type);
  });

  if (!isEditing) {
    return null;
  }

  // If we conditionally show/hide the button instead of disabling it when dragging,
  // the change in height of the page messes with the drag and drop.
  // causing this reported issue: https://zapierorg.atlassian.net/browse/INTRFCS-3040
  return (
    <AddBlockButton
      disabled={isDragging || addBlock === undefined}
      onClick={() => addBlock?.()}
    />
  );
}
