import { Controller, useForm } from "react-hook-form";

import { trpc } from "utils/trpc";

import { Spacer } from "@zapier/design-system";

import { Button } from "@/block-system/brickz/components/ui/Button";
import { Field } from "@/block-system/brickz/components/ui/Field";
import { TextInput } from "@/block-system/brickz/components/ui/TextInput";
import { FormContainer } from "@/block-system/components/forms/FormContainer";
import { zodResolver } from "@hookform/resolvers/zod";
import { styled } from "lib/theme";
import { OTP_REGEX } from "utils/regex";
import { z } from "zod";
import { Content } from "../";

const Instructions = styled.div`
  text-align: left;
  margin-bottom: -5px;

  p {
    font-size: 15px;
    padding-top: 10px;
  }
`;

const TryAgain = styled.div`
  p {
    font-size: 14px;
  }
  a {
    color: inherit;
  }
`;

const FormDataSchema = z.object({
  otp: z.string().regex(OTP_REGEX, "Must be a 6-digit number"),
});
type FormData = z.infer<typeof FormDataSchema>;

export function Step2CheckOTP(props: {
  projectId: string;
  next: () => void;
  back: () => void;
  onFail: (msg: string) => void;
}) {
  const { mutateAsync: checkOTP } =
    trpc.projectAuth.managedConsumerAuthCheck.useMutation();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isSubmitSuccessful, errors },
  } = useForm<FormData>({
    resolver: zodResolver(FormDataSchema),
  });

  const onSubmit = async ({ otp }: FormData) => {
    const resp = await checkOTP({
      projectId: props.projectId,
      otp,
    });
    if (resp.success) {
      props.next();
    } else {
      props.onFail("Login attempt failed, try again!");
    }
  };

  return (
    <Content>
      <FormContainer>
        <Instructions>
          <h3 className={"text-h3"}>Check your email</h3>
          <p className={"text-p"}>
            A 6-digit code has been sent to your email address. Enter it below
            to log in.
          </p>
        </Instructions>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="otp"
            control={control}
            render={({ field }) => (
              <Field
                label="6-digit code"
                renderInput={(inputProps) => (
                  <TextInput
                    {...inputProps}
                    {...field}
                    type="text"
                    autoFocus={true}
                  />
                )}
                error={errors.otp?.message}
              />
            )}
          />
          <Spacer height={30} />
          <Button
            className="w-full"
            type="submit"
            isLoading={isSubmitting || isSubmitSuccessful}
          >
            Log in
          </Button>
        </form>
      </FormContainer>
      <Spacer height={40} />
      <TryAgain>
        <p className={"text-p"}>
          Didn't get an email?{" "}
          <a
            className="text-a"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              props.back();
            }}
          >
            Try again
          </a>
        </p>
      </TryAgain>
    </Content>
  );
}
