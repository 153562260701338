export const UNDEFINED_REF = { current: undefined };

export const ALIGNMENT_TO_MARGIN = {
  left: "0 0",
  right: "0 0 0 auto",
  center: "0 auto",
} as const;

export const ALIGNMENT_TO_CLASSNAMES = {
  left: "my-0 mx-0",
  right: "m-0 ml-auto",
  center: "my-0 mx-auto",
} as const;

export const MAX_WIDTH_TO_CLASSNAMES = {
  "368px": "max-w-[368px]",
  "520px": "max-w-[520px]",
  "864px": "max-w-[864px]",
  "1156px": "max-w-[1156px]",
  "98%": "max-w-[98%]",
  "100%": "max-w-full",
} as const;

export const MAX_WIDTH_NAME_TO_CLASSNAMES = {
  narrow: "max-w-[520px]",
  medium: "max-w-[864px]",
  wide: "max-w-[1156px]",
  full: "max-w-[98%]",
} as const;
