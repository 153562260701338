import { cn } from "@/block-system/brickz/lib/utils";
import { MediaBlock } from "../types";
import { ReactNode } from "react";
import { Image as ImageIcon, Play as PlayIcon } from "lucide-react";
import { StandardBlockZeroState } from "@/block-system/components/BlockErrors/StandardBlockZeroState";

const MediaTypeIcons: Record<MediaBlock["config"]["mediaType"], ReactNode> = {
  image: <ImageIcon size={32} />,
  video: <PlayIcon size={32} />,
  logo: <ImageIcon size={32} />,
};

export function MediaZeroState({
  mediaType,
}: {
  mediaType: MediaBlock["config"]["mediaType"];
}) {
  return (
    <StandardBlockZeroState
      icon={MediaTypeIcons[mediaType]}
      className={cn({
        "h-[300px] w-[412px] min-w-[300px]": mediaType === "video",
        "h-[200px] w-[412px] min-w-[300px]": mediaType === "image",
      })}
    />
  );
}
