import { useCurrentPages } from "lib/context/current-pages-context";
import { ButtonBlock } from "../types";
import { ButtonAction } from "../schema";
import { useRouter } from "next/router";
import { navigateToPage } from "block-system/blocks/__shared__/lib/actions/navigation";
import { openExternalUrl } from "block-system/blocks/__shared__/lib/actions/openExternalUrl";

import { trpc } from "utils/trpc";
import { toast } from "@/components/Toaster";
import {
  ButtonPresentational,
  getButtonTailwindStyles,
} from "./ButtonPresentational";

export const Button = (props: ButtonBlock["config"] & { blockId: string }) => {
  const styles = getButtonTailwindStyles(props);
  const pages = useCurrentPages();
  const router = useRouter();

  const { mutateAsync: registerButtonClick, isPending: isTriggeringClick } =
    trpc.buttons.triggerClick.useMutation();

  const handleButtonAction = async (action: ButtonAction) => {
    if (!props.actions.length) return;
    const type = action.type;

    switch (type) {
      case "navigate":
        return await navigateToPage(action, pages, router);
      case "openExternalUrl":
        return openExternalUrl(action);
      case "notification":
        return toast.success(
          { message: action.config.message },
          {
            position: action.config.position ?? "bottom-center",
          }
        );
      case "zap":
        if (props.id) return await registerButtonClick({ buttonId: props.id });
        break;
      default:
        const _exhaustiveCheck: never = type;
        return _exhaustiveCheck;
    }
  };

  const handleButtonClick = () => {
    if (!props.actions.length) return;
    props.actions.forEach((action) => {
      void handleButtonAction(action);
    });
  };

  return (
    <ButtonPresentational
      {...props}
      styles={styles}
      handleButtonAction={handleButtonClick}
      disabled={isTriggeringClick}
    />
  );
};
