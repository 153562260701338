import { type InterfacesTheme } from "lib/theme/ThemeProvider";
import { getTailwindColors } from "uicolors-generator";
import { adjustColor, standardDesaturation } from "./brandedTheme-helpers";
import { InterfacesThemeSchema } from "../../schema";

export function brandedTheme(brandColor: string): InterfacesTheme {
  const baseTheme = InterfacesThemeSchema.parse({});

  const palette = getTailwindColors(brandColor, { asHex: true });

  const commonForeground = standardDesaturation(palette["950"]);
  const commonDarkForeground = standardDesaturation(palette["50"]);

  return {
    ...baseTheme,

    brandColor,

    // Light theme colors
    background: palette["50"],
    foreground: commonForeground,
    cardForeground: commonForeground,
    popoverForeground: commonForeground,
    secondaryForeground: commonForeground,
    primary: palette["500"],
    secondary: standardDesaturation(palette["50"]),
    muted: adjustColor(palette["100"], { saturation: -20 }),
    mutedForeground: adjustColor(palette["900"], { saturation: -60 }),
    accent: palette["100"],
    accentForeground: palette["700"],
    ring: palette["500"],
    border: adjustColor(palette["200"], { saturation: -90 }),
    input: adjustColor(palette["200"], { saturation: -90 }),

    // Dark theme colors
    darkBackground: adjustColor(palette["950"], {
      saturation: -80,
      lightness: -70,
    }),
    darkForeground: commonDarkForeground,
    darkCard: adjustColor(palette[950], {
      saturation: -90,
      lightness: -60,
    }),
    darkCardForeground: commonDarkForeground,
    darkPopoverForeground: commonDarkForeground,
    darkSecondaryForeground: commonDarkForeground,
    darkPrimary: palette["500"],
    darkSecondary: adjustColor(palette["950"], {
      saturation: -80,
      lightness: -30,
    }),
    darkMuted: adjustColor(palette["950"], {
      saturation: -50,
      lightness: -20,
    }),
    darkMutedForeground: standardDesaturation(palette["200"]),
    darkAccent: palette["900"],
    darkAccentForeground: palette["50"],
    darkRing: palette["500"],
    darkBorder: adjustColor(palette["950"], {
      saturation: -95,
    }),
    darkInput: adjustColor(palette["900"], {
      saturation: -95,
    }),
  };
}
