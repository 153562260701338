import BlockWrapper from "block-system/components/BlockWrapper";
import { usePageContext } from "lib/context/page-context";
import { useSafeUrl } from "lib/hooks/useSafeUrl";
import { BlockProps } from "block-system/blocks/__shared__/types";
import { LinksCardBlock as LinksCardBlockType } from "../types";
import { useTrackingContext } from "observability";
import { getHostnameFromUrl } from "lib/utils/getHostnameFromUrl";
import { LinksCard } from "./LinksCard";
import { getPageUrl } from "lib/route-helpers";
import { useCurrentPages } from "lib/context/current-pages-context";
import { useMemo } from "react";
import { useCurrentConsumerProject } from "@/lib/context/current-consumer-project-context";

export function LinksCardBlock(props: BlockProps<LinksCardBlockType>) {
  const { emitConsumerInteractionEvent } = useTrackingContext();
  const { pageId, projectId, isThumbnail, isEmbedded } = usePageContext();
  const project = useCurrentConsumerProject();
  const pages = useCurrentPages();

  const { config } = props.block;

  const { value: url, isRelative } = useSafeUrl(config.url);

  const linkUrl = useMemo(() => {
    if (!config.linkType || config.linkType === "url") {
      return url;
    }
    if (!pages || !project) return "";
    const page = pages.find((page) => page.id === config.pageId);
    if (!page) return "";

    if (isEmbedded) return `/_z/embed/page/${page.id}`;
    return getPageUrl(project.slug, page.slug, project.customDomain);
  }, [config.linkType, config.pageId, isEmbedded, pages, project, url]);

  const handleClick = () => {
    // extract hostnames from window, link, for comparison
    const { hostname } = window.location;
    const linkHostname = getHostnameFromUrl(url);

    // Links to other pages within app are treated differently
    const isAppLink = isRelative || hostname === linkHostname;

    emitConsumerInteractionEvent({
      interaction_goal: "Open link in linked cards",
      event_action: "click",
      option_selected: isAppLink ? "internal" : "external",
      interfaces_project_id: projectId,
      interfaces_page_id: pageId,
      interfaces_component_type: "link_cards",
      embedded_flag: isEmbedded,
    });
  };

  return (
    <BlockWrapper blockId={props.blockId} block={props.block} maxWidth="368px">
      <LinksCard
        title={config.title}
        description={config.description}
        linkText={config.linkText}
        emoji={config.emoji}
        href={isThumbnail ? "" : linkUrl}
        target={config.openInNewTab ? "_blank" : "_self"}
        onClick={handleClick}
      />
    </BlockWrapper>
  );
}
