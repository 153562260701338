import { ThemePresetSchema } from "../../schema";
import { brandedTheme } from "./brandedTheme";
import { PresetTheme } from "./types";

export const defaultTheme = {
  ...brandedTheme("#2563EB"),
  primary: "#2563EB",
  darkPrimary: "#2563EB",
  preset: ThemePresetSchema.enum.Default,
};

/**
 * Keeping this data in an object makes it really easy to ensure
 * that we defined ALL presets.
 */
export const presetThemesByTitle: Record<PresetTheme["title"], PresetTheme> = {
  Default: {
    title: "Default",
    theme: defaultTheme,
  },
  Black: {
    title: "Black",
    theme: {
      ...brandedTheme("#2D2E2E"),
      primary: "#2D2E2E",
      darkPrimary: "#2D2E2E",
      preset: ThemePresetSchema.enum.Black,
    },
  },
  Purple: {
    title: "Purple",
    theme: {
      ...brandedTheme("#7E00A1"),
      primary: "#7E00A1",
      darkPrimary: "#7E00A1",
      preset: ThemePresetSchema.enum.Purple,
    },
  },
  Green: {
    title: "Green",
    theme: {
      ...brandedTheme("#4CA154"),
      primary: "#4CA154",
      darkPrimary: "#4CA154",
      preset: ThemePresetSchema.enum.Green,
    },
  },
  Red: {
    title: "Red",
    theme: {
      ...brandedTheme("#CF364C"),
      primary: "#CF364C",
      darkPrimary: "#CF364C",
      preset: ThemePresetSchema.enum.Red,
    },
  },
  Orange: {
    title: "Orange",
    theme: {
      ...brandedTheme("#E87B35"),
      primary: "#E87B35",
      darkPrimary: "#E87B35",
      preset: ThemePresetSchema.enum.Orange,
    },
  },
};

export const presetThemes = Object.values(
  presetThemesByTitle
) satisfies PresetTheme[];
