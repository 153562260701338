/* eslint-disable @next/next/no-img-element */
import { MediaBlock } from "../types";
import { useCurrentConsumerProject } from "@/lib/context/current-consumer-project-context";

export function LogoBlock({ alt }: { alt: MediaBlock["config"]["alt"] }) {
  const project = useCurrentConsumerProject();

  return (
    <>
      {project.logo?.url ? (
        <img src={project.logo.url} alt={alt ?? ""} />
      ) : null}
    </>
  );
}
